import React, { useEffect, useState } from 'react'
import "../../../assets/style/addLeads.css"
import Services from '../../../Helper/services'
import ReactLoader from '../../../Loader/react-loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
import ERR_MESSAGE from '../../../Helper/error-helper';
import { useLocation } from 'react-router-dom';
import DatePickerValue from '../../../components/date-picker';
import TITLES from '../../../Helper/title';
import { Helmet } from 'react-helmet';
const EditLead = () => {
    const location = useLocation()
    const leadId = location.state.id
    const [open, setOpen] = useState(false)
    const [prospectTypes, setProspectTypes] = useState([])
    const [locations, setLocations] = useState([])
    const [staffs, setStaffs] = useState([])
    const [errors, setErrors] = useState("");
    const [notYes, setNotYes] = useState([])
    const [loading, setLoading] = useState(false)
    const [callResponses, setCallResponses] = useState([])
    const [priorites, setPriorities] = useState([])
    const [branch, setBranch] = useState([]);
    const [fields, setFields] = useState({
        call_date: "",
        call_response: "",
        priority: "",
        next_call_date: "",
        next_call_time: "",
        staff_assign_id: "",
        remarks: "",
    })
    const [fields1, setFields1] = useState({
        mobile_no: "",
        whatsapp_no: "",
        name: "",
        prospect_date: "",
        prospect_type: "",
        source: "",
        gender: "",
        location: "",
        conversation: "",
        branch_id: ""
    })

    const setProspectDate = (newDate) => {
        setFields1((prevFields) => ({
            ...prevFields,
            prospect_date: newDate,
        }));
    };


    const setCallate = (newDate) => {
        setFields((prevFields) => ({
            ...prevFields,
            call_date: newDate,
        }));
    };


    const setNextCallDate = (newDate) => {
        setFields((prevFields) => ({
            ...prevFields,
            next_call_date: newDate,
        }));
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        const whatsappRegex = /^\d+$/;
        const emojiSymbolRegex = /[^\p{L}\s]/u;
        const { name, mobile_no, whatsapp_no, branch_id, source, conversation } = fields1

        if (!name || name?.trim().length === 0) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.EMPTY_FULLNAME;
        }
        else if (name.length < 3 && name.length > 0) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_FULLNAME_MIN;
        }
        else if (name.length > 30 && name.length > 0) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_FULLNAME_MAX;
        }
        else {
            const regex = /^[a-zA-Z\s]*$/;
            if ((!regex.test(name) || emojiSymbolRegex.test(name)) && name.length > 0) {
                formIsValid = false;
                errors["name"] = ERR_MESSAGE.INVALID_FULLNAME;
            }
        }
        if (!mobile_no || mobile_no?.trim().length === 0) {
            formIsValid = false;
            errors["mobile_no"] = ERR_MESSAGE.MOBILE_EMPTY
        }

        else if (!whatsappRegex.test(mobile_no) && mobile_no.length > 0) {
            formIsValid = false;
            errors["mobile_no"] = ERR_MESSAGE.INVALID_MOBILE
        }
        else if (mobile_no.length < 10 && mobile_no.length > 0) {
            formIsValid = false;
            errors["mobile_no"] = ERR_MESSAGE.INVALID_MOBILE_MIN
        }
        if (!whatsapp_no || whatsapp_no?.trim().length === 0) {
            formIsValid = false;
            errors["whatsapp_no"] = ERR_MESSAGE.WHATSAPP_EMPTY
        }
        else if (!whatsappRegex.test(whatsapp_no) && whatsapp_no.length > 0) {
            formIsValid = false;
            errors["whatsapp_no"] = ERR_MESSAGE.INVALID_MOBILE
        }
        else if (whatsapp_no.length < 10 && whatsapp_no.length > 0) {
            formIsValid = false;
            errors["whatsapp_no"] = ERR_MESSAGE.INVALID_MOBILE_MIN
        }
        if (!branch_id || branch_id.length === 0) {
            formIsValid = false;
            errors["branch_id"] = ERR_MESSAGE.EMPTY_BRANCH;
        }
        // if (!prospect_date || prospect_date.length === 0) {
        //     formIsValid = false;
        //     errors["prospect_date"] = ERR_MESSAGE.EMPTY_DATE;
        // } else {
        //     const currentDate = new Date();
        //     currentDate.setHours(0, 0, 0, 0);

        //     const selectedDate = new Date(prospect_date);
        //     selectedDate.setHours(0, 0, 0, 0);

        //     const sevenDaysAgo = new Date();
        //     sevenDaysAgo.setDate(currentDate.getDate() - 7);
        //     sevenDaysAgo.setHours(0, 0, 0, 0);

        //     if (selectedDate < sevenDaysAgo || selectedDate > currentDate) {
        //         formIsValid = false;
        //         errors["prospect_date"] = ERR_MESSAGE.INVALID_DATE;
        //     }
        // }
        if (!source || source.length === 0) {
            formIsValid = false;
            errors["source"] = ERR_MESSAGE.EMPTY_SOURCE;
        }
        if (!conversation) {
            formIsValid = false;
            errors["conversation"] = ERR_MESSAGE.EMPTY_CONVERSATION;
        }
        if (open === true || fields1.conversation != 0) {
            const { call_date, call_response, priority, next_call_date, remarks, next_call_time } = fields
            if (!call_date || call_date.length === 0) {
                formIsValid = false;
                errors["call_date"] = ERR_MESSAGE.EMPTY_DATE;
            }
            else {
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0);

                const selectedDate = new Date(call_date);
                selectedDate.setHours(0, 0, 0, 0);

                const sevenDaysAgo = new Date();
                sevenDaysAgo.setDate(currentDate.getDate() - 7);
                sevenDaysAgo.setHours(0, 0, 0, 0);

                if (selectedDate < sevenDaysAgo || selectedDate > currentDate) {
                    formIsValid = false;
                    errors["call_date"] = ERR_MESSAGE.INVALID_DATE;
                }
            }
            if (!call_response || call_response.length === 0) {
                formIsValid = false;
                errors["call_response"] = ERR_MESSAGE.EMPTY_CALLRESPONSE;
            }
            if (fields?.call_response === "Ask for call again" || fields.call_response == 'Not answered') {
                if (!priority || priority?.length === 0) {
                    formIsValid = false;
                    errors["priority"] = ERR_MESSAGE.EMPTY_PRIORITY;
                }
                if (!next_call_date || next_call_date?.length === 0) {
                    formIsValid = false;
                    errors["next_call_date"] = ERR_MESSAGE.EMPTY_DATE;
                }
                else {
                    const currentDate = new Date();
                    currentDate.setHours(0, 0, 0, 0);

                    const selectedDate = new Date(next_call_date);
                    selectedDate.setHours(0, 0, 0, 0);

                    if (selectedDate < currentDate) {
                        formIsValid = false;
                        errors["next_call_date"] = ERR_MESSAGE.INVALID_DATE;
                    }
                }
                if (!next_call_time || next_call_time?.length === 0) {
                    formIsValid = false;
                    errors["next_call_time"] = ERR_MESSAGE.EMPTY_TIME;
                }
                else if (remarks.length > 300 && remarks.length > 0) {
                    formIsValid = false;
                    errors["remarks"] = ERR_MESSAGE.EMPTY_REMARK
                }
            }
            if (fields.call_response === "Interested" || fields.call_response == 'Not answered') {
                if (!remarks || remarks.length === 0) {
                    formIsValid = false;
                    errors["remarks"] = ERR_MESSAGE.EMPTY_REMARK;
                }
                else if (remarks.length > 300) {
                    formIsValid = false;
                    errors["remarks"] = ERR_MESSAGE.INVALID_REMARK;
                }
            }
        }
        setErrors(errors);
        return formIsValid;
    }

    const handleChange = (e) => {
        setErrors("")
        const name = e.target.name
        const value = e.target.value
        setFields({
            ...fields,
            [name]: value
        })
    }

    const handleChange1 = (e) => {
        setErrors("")
        const name = e.target.name
        const value = e.target.value
        setFields1({
            ...fields1,
            [name]: value
        })
        if (name === "conversation") {
            if (e.target.value == "1") {
                setOpen(true)
            }
            else {
                setOpen(false)
            }
        }
    }
    async function getApi() {
        setLoading(true)
        let result = await Services.getService(`lead/get-lead-details?id=${location?.state?.id}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setFields1(result?.body)
            setNotYes(result?.body)
            if (result.body.conversation_data[0] === undefined) {
                setFields({})
            }
            else {
                setFields(result?.body?.conversation_data[0]);
            }
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }

    useEffect(() => {
        getApi()
    }, [])

    async function handleSubmit(e) {
        e.preventDefault();
        if (handleValidation()) {
            let data;

            if (open == true || fields1.conversation != 0) {
                data = JSON.stringify({
                    "id": leadId,
                    "company_id": localStorage.getItem("company_id"),
                    // "branch_id": localStorage.getItem("branch_id"),
                    "mobile_no": fields1?.mobile_no,
                    "whatsapp_no": fields1.whatsapp_no,
                    "name": fields1?.name,
                    "prospect_date": fields?.prospect_date,
                    "source": fields1?.source,
                    "gender": fields1?.gender,
                    "location": fields1?.location,
                    "conversation": fields1?.conversation,
                    "leads_conversation": {
                        "call_response": fields?.call_response,
                        "priority": fields?.priority,
                        "next_call_date": fields?.next_call_date,
                        "next_call_time": fields?.next_call_time,
                        "call_date": fields?.call_date,
                        "remarks": fields?.remarks,
                        "staff_assign_id": fields?.staff_assign_id,
                        "branch_id": fields1.branch_id

                    }
                });
            } else {
                data = JSON.stringify({
                    "id": leadId,
                    "company_id": localStorage.getItem("company_id"),
                    // "branch_id": localStorage.getItem("branch_id"),
                    "mobile_no": fields1?.mobile_no,
                    "whatsapp_no": fields1.whatsapp_no,
                    "name": fields1?.name,
                    "prospect_date": fields?.prospect_date,
                    "source": fields1?.source,
                    "gender": fields1?.gender,
                    "location": fields1?.location,
                    "conversation": fields1?.conversation,
                    "branch_id": fields1.branch_id

                });
            }
            setLoading(true);
            try {
                const result = await Services.postService("lead/edit-leads", data);

                if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                    setLoading(false);
                    toast.success("Detail updated successfully");
                    getApi();
                } else {
                    setLoading(false);
                    toast.error(result.message);
                }
            } catch (error) {

            }
        }

    }

    async function staffApi() {
        const branch_id = localStorage.getItem("branch_id")
        let result = await Services.getService(`lead/staff-list?&branch_id=${branch_id}`);
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setStaffs(result?.body)
        }
        else {
        }
    }
    async function leadData() {
        let result = await Services.getService("lead/leads-sources");
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setCallResponses(result?.body?.response)
            setPriorities(result?.body?.priority)
            setLocations(result?.body?.leadData)
            setProspectTypes(result?.body?.sources)
        }
        else {
        }
    }
    useEffect(() => {
        staffApi()
        leadData()
        getBranchApi()
    }, [])
    async function getBranchApi() {
        const company_id = localStorage.getItem("company_id")
        let result = await Services.getService(`admin/role-branch-list?id=${company_id}`);
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setBranch(result?.body?.branch)
        }
        else {
        }
    }
    const handleChange2 = (event) => {
        setErrors("")
        const { name, value } = event.target;
        if (value.length > 600) {
            setErrors({ ...errors, remarks: ERR_MESSAGE.INVALID_REMARK });
            setFields({ ...fields, [name]: value });
        } else {
            setFields({ ...fields, [name]: value });
            setErrors("")
        };
    }
    return (
        <div>
            <Helmet>
                <title>{TITLES.EDIT_LEAD}</title>
            </Helmet>
            <ToastContainer />
            {loading && <ReactLoader />}
            <section className="add-customer common-bg form-height">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="add-customer-head">
                                <h2 className="add-customer-text fw-600">Edit lead</h2>
                            </div>
                        </div>
                        <div className="col-12">
                            <form >
                                <div className="row">
                                    <div className="col-12">
                                        <div className="basic-info my-4">
                                            <h4 className="basic-head fw-600">Basic Info</h4>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                        <div className="mb-3">
                                            <label className="form-label">Name</label>
                                            <input type="text" onChange={handleChange1} name='name' value={fields1?.name} className="form-control common-input" placeholder="Enter Name" />
                                            {errors.name && <span className="err_msg">{errors.name}</span>}
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                        <div className="mb-3">
                                            <label className="form-label">Mobile No</label>
                                            <input type="text" onChange={handleChange1} name='mobile_no' value={fields1?.mobile_no.slice(0, 10)} className="form-control common-input" placeholder="Enter Mobile No" />
                                            {errors.mobile_no && <span className="err_msg">{errors.mobile_no}</span>}
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                        <div className="mb-3">
                                            <label className="form-label">Whatsapp No</label>
                                            <input type="text" onChange={handleChange1} name='whatsapp_no' value={fields1?.whatsapp_no?.slice(0, 10)} className="form-control common-input" placeholder="Enter Mobile No" />
                                            {errors.whatsapp_no && <span className="err_msg">{errors.whatsapp_no}</span>}
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                        <div className="mb-3">
                                            <label className="form-label">Prospect Date</label>

                                            <DatePickerValue
                                                setErrors={setErrors}
                                                value={fields1?.prospect_date}
                                                setFn={setProspectDate}
                                            />
                                            {errors.prospect_date && <span className="err_msg">{errors.prospect_date}</span>}

                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                        <div className="mb-3">
                                            <label className="form-label">Source</label>
                                            <select name='source' onChange={handleChange1} value={fields1?.source} className="form-select common-input">
                                                {
                                                    prospectTypes?.map((item) => {
                                                        return (
                                                            <>
                                                                <option key={item.id} value={item.name}>{item.name}</option>
                                                            </>
                                                        )
                                                    })
                                                }

                                            </select>
                                            {errors.source && <span className="err_msg">{errors.source}</span>}

                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                        <div className="mb-3">
                                            <label className="form-label">Gender</label>
                                            <select name='gender' onChange={handleChange1} value={fields1?.gender} className="form-select common-input">

                                                <option value={fields1?.gender}>{fields1?.gender == 1 ? "Male" : fields1?.gender == 2 ? "Female" : fields1?.gender == 3 ? "Others" : "Select"}</option>
                                                {fields1?.gender == "2" ? null : <option value="2">Female</option>}
                                                {fields1?.gender == "1" ? null : <option value="1">Male</option>}
                                                {fields1?.gender == "3" ? null : <option value="3">Others</option>}
                                            </select>
                                            {errors.gender && <span className="err_msg">{errors.gender}</span>}

                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                        <div className="mb-3">
                                            <label className="form-label">Location</label>

                                            <input type="text" name='location' value={fields1?.location} onChange={handleChange1} className="form-control common-input" placeholder="Enter location" />                                 
                                        {errors.location && <span className="err_msg">{errors.location}</span>}

                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                    <div className="mb-3">
                                        <label className="form-label">Branch</label>
                                        {
                                            localStorage.getItem("role_id") == 1
                                                ?
                                                <select type="text" name='branch_id' value={fields1.branch_id} onChange={handleChange1} className="form-select common-input" disabled aria-label="Enter Role">
                                                    {/* <option value="">Select</option> */}
                                                    {
                                                        branch.filter(item => item.id == localStorage.getItem("branch_id"))?.map((item) => {
                                                            return (
                                                                <option key={item.id} value={item.id}>{item?.address_line_2},{item.city}</option>

                                                            )
                                                        })
                                                    }
                                                </select> :
                                                <select type="text" name='branch_id' value={fields1.branch_id} onChange={handleChange1} className="form-select common-input" aria-label="Enter Role">
                                                    <option value="">Select</option>
                                                    {
                                                        branch?.map((item) => {
                                                            return (
                                                                <option key={item.id} value={item.id}>{item?.address_line_2},{item.city}</option>

                                                            )
                                                        })
                                                    }
                                                </select>
                                        }

                                        {errors.branch && <span className="err_msg">{errors.branch}</span>}
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                    <div className="mb-3">
                                        <label className="form-label">Conversation</label>
                                        <select name='conversation' onChange={handleChange1} value={fields1.conversation} className="form-select common-input">
                                            {fields1.conversation === '0' ? (
                                                <>
                                                    <option value="0">No</option>
                                                    <option value="1">Yes</option>
                                                </>
                                            ) : notYes.conversation === "1" ? (
                                                <>
                                                    <option value="1">Yes</option>
                                                    <option disabled value="0">No</option>
                                                </>
                                            ) : <>
                                                <option value="1">Yes</option>
                                                <option value="0">No</option>

                                            </>}
                                        </select>
                                    </div>
                                </div>

                                {
                                    (open === true || fields1?.conversation != 0) && <>
                                        <div className="col-12">
                                            <div className="basic-info my-4">
                                                <h4 className="basic-head fw-600">Conversation</h4>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                            <div className="mb-3">
                                                <label className="form-label">Call Date</label>

                                                <DatePickerValue
                                                    setErrors={setErrors}
                                                    value={fields?.call_date === undefined ? "" : fields?.call_date}
                                                    setFn={setCallate}
                                                />
                                                {errors.call_date && <span className="err_msg">{errors.call_date}</span>}
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                            <div className="mb-3">
                                                <label className="form-label">Call Response</label>
                                                <select name='call_response' value={fields?.call_response} onChange={handleChange} className="form-select common-input">
                                                    <option value="">Select</option>
                                                    {callResponses?.map((item) => (
                                                        <option key={item.id} value={item.name}>
                                                            {item.name}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.call_response && <span className="err_msg">{errors.call_response}</span>}

                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                            <div className="mb-3">
                                                <label className="form-label">Priority</label>
                                                <select name='priority' value={!fields?.call_response  || fields?.call_response === 'Interested'  ? "" : fields?.priority} onChange={handleChange} className="form-select common-input" disabled={!fields?.call_response || fields?.call_response === 'Interested' }>
                                                    <option value="">Select</option>

                                                    {
                                                        priorites?.map((item) => (
                                                            <>
                                                                <option key={item.id} value={item.name}>{item.name}</option>
                                                            </>
                                                        ))
                                                    }


                                                </select>
                                                {errors.priority && <span className="err_msg">{errors.priority}</span>}

                                            </div>
                                        </div>

                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                            <div className="mb-3">
                                                <label className="form-label">Next Call Date </label>

                                                <DatePickerValue
                                                    setErrors={setErrors}
                                                    value={fields?.next_call_date === undefined || fields?.call_response === 'Interested' || fields?.call_response === 'Not interested' || !fields?.call_response? "" : fields?.next_call_date}
                                                    setFn={setNextCallDate}
                                                    interested1={fields?.call_response === 'Interested'}
                                                    notInterested1={fields?.call_response === 'Not interested'}
                                                    callResponse1={!fields?.call_response}
                                                // notAnswered1={fields?.call_response === 'Not answered'}
                                                />

                                                {errors.next_call_date && <span className="err_msg">{errors.next_call_date}</span>}

                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                            <div className="mb-3">
                                                <label className="form-label">Next Call Time</label>

                                                <input name='next_call_time' value={!fields?.call_response || fields?.call_response === 'Interested'  ? " " : fields?.next_call_time} onChange={handleChange} type="time" className="form-control common-input time-input0"
                                                    disabled={!fields?.call_response || fields?.call_response === 'Interested' } />
                                                {errors.next_call_time && <span className="err_msg">{errors.next_call_time}</span>}

                                            </div>
                                        </div>
                                        {/* 
                                            {
                                                (role_id === "1" || role_id === "2") && */}
                                        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                                            <div className="mb-3">
                                                <label className="form-label">Staff Assign</label>
                                                <select name='staff_assign_id' value={!fields?.call_response || fields?.call_response === 'Interested' ? "" : fields?.staff_assign_id} onChange={handleChange} disabled={!fields?.call_response || fields?.call_response === 'Interested' } className="form-select common-input">
                                                    <option selected>Select</option>
                                                    {
                                                        staffs?.map((item) => (
                                                            <>
                                                                <option key={item.id} value={item.id}>{item.name}</option>
                                                            </>
                                                        ))
                                                    }

                                                </select>

                                            </div>
                                        </div>
                                        {/* } */}
                                        <div className="col-12">
                                            <div className="mb-3">
                                                <label className="form-label">Remark</label>
                                                <textarea name='remarks' value={fields?.remarks} onChange={handleChange2} className="form-control common-input remark-textarea" rows="4" disabled={!fields?.call_response || fields?.call_response === 'Not interested'} placeholder="Enter Remark"></textarea>
                                                {errors.remarks && <span className="err_msg">{errors.remarks}</span>}

                                            </div>
                                        </div>
                                    </>}

                                <div className="col-12 d-flex justify-content-end align-self-end">
                                    <div className="col-12 d-flex justify-content-end align-self-end">
                                        <button
                                            type="button"
                                            className="next-btn"
                                            onClick={handleSubmit}
                                        >
                                            Submit
                                        </button>
                                    </div>

                                </div>

                        </div>

                    </form>

                </div>
        </div>
                </div >
            </section >
        </div >
    )
}

export default EditLead;
