import React from 'react'
import "../../../assets/style/addLeads.css"
import DatePickerValue from '../../../components/date-picker'

function UpdateConversation(props) {
    return (
        <>
            <div className="col-12">
                <div className="basic-info my-4">
                    <h4 className="basic-head fw-600">Conversation</h4>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <div className="mb-3">
                    <label className="form-label">Call Date</label>
                    <DatePickerValue
                        setErrors={props.setErrors}
                        value={props.fields?.callDate}
                        setFn={props.setCallate}
                    />
                    {props?.errors?.callDate && <span className="err_msg">{props?.errors?.callDate}</span>}
                </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <div className="mb-3">
                    <label className="form-label">Call Response</label>
                    <select name='callResponse' value={props?.fields?.callResponse} onChange={props?.handleChange} className="form-select common-input">
                        <option value="" selected>Select</option>
                        {
                            props?.callResponses?.map((item) => {
                                return (
                                    <>
                                        <option key={item.id} value={item.name}>{item.name}</option>
                                    </>
                                )
                            })
                        }
                    </select>
                    {props?.errors?.callResponse && <span className="err_msg">{props?.errors?.callResponse}</span>}
                </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <div className="mb-3">
                    <label className="form-label">Priority</label>
                    <select name='priority' value={props?.fields?.priority} onChange={props?.handleChange} className="form-select common-input"
                        disabled={!props?.fields?.callResponse || props?.fields?.callResponse === 'Interested' || props?.fields?.callResponse === 'Not interested'}
                    >
                        <option value="" selected>Select</option>
                        {
                            props?.priorites?.map((item) => (
                                <>
                                    <option key={item.id} value={item.name}>{item.name}</option>
                                </>
                            ))
                        }
                    </select>
                    {props?.errors?.priority && <span className="err_msg">{props?.errors?.priority}</span>}
                </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <div className="mb-3">
                    <label className="form-label">Next Call Date</label>
                    <DatePickerValue
                        setErrors={props.setErrors}
                        value={props.fields?.nextCallDate}
                        setFn={props.setNextCallDate}
                        interested={props?.fields?.callResponse === 'Interested'}
                        notInterested={props?.fields?.callResponse === 'Not interested'}
                        callResponse={!props?.fields?.callResponse}
                        // notAnswered={props?.fields?.callResponse === 'Not answered'}
                    />
                    {props?.errors?.nextCallDate && <span className="err_msg">{props?.errors?.nextCallDate}</span>}
                </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                <div className="mb-3">
                    <label className="form-label">Next Call Time</label>
                    <input name='nextCallTime' value={props?.fields?.nextCallTime} onChange={props?.handleChange} type="time" className="form-control common-input time-input0"
                        disabled={!props?.fields?.callResponse  || props?.fields?.callResponse === 'Interested' || props?.fields?.callResponse === 'Not interested'} />
                    {props?.errors?.nextCallTime && <span className="err_msg">{props?.errors?.nextCallTime}</span>}
                </div>
            </div>
            {/* {
                (props.role_id === "1" || props.role_id === "2") &&  */}
                <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <div className="mb-3">
                        <label className="form-label">Staff Assign</label>
                        <select name='staff' value={props?.fields?.staff} onChange={props?.handleChange} className="form-select common-input"
                            disabled={!props?.fields?.callResponse || props?.fields?.callResponse === 'Interested' || props?.fields?.callResponse === 'Not interested'}>
                            <option value="" selected>Select</option>
                            {
                                props?.staffs?.map((item) => (
                                    <>
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    </>
                                ))
                            }
                        </select>
                        {props?.errors?.staff && <span className="err_msg">{props?.errors?.staff}</span>}
                    </div>
                </div>
            {/* } */}

            <div className="col-12">
                <div className="mb-3">
                    <label className="form-label">Remark</label>
                    <textarea name='remark' value={props?.fields?.remark} onChange={props?.handleChange1} className="form-control common-input remark-textarea" rows="4" placeholder="Enter Remark"
                        disabled={!props?.fields?.callResponse  || props?.fields?.callResponse === 'Not interested'}></textarea>
                    {props?.errors?.remark && <span className="err_msg">{props?.errors?.remark}</span>}
                </div>
            </div>
        </>

    )
}

export default UpdateConversation