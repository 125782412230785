import React, { useState, useEffect } from "react";
import { LOGIN_LOGO, NOTIFICATION_ICON, PROFILE_ICON, SEARCH_ICON } from "../Middleware/images";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import "../assets/style/header.css"
import ROUTES from "../Middleware/routes";
import { useLayoutContext } from "../context/context";
import Services from "../Helper/services";
import DEVELOPMENT_CONFIG from "../Helper/config";
function Header() {
    const [searchTerm, setSearchTerm] = useState("");
    const [searchStart, setSearchStart] = useState(false)
    const [branch, setBranch] = useState([]);
    const navigate = useNavigate();
    const { setSearchQuery } = useLayoutContext();
    const role = localStorage.getItem('role_id')
    const [selectedBranch, setSelectedBranch] = useState("");
    const location = useLocation()
    const handleClick = () => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("company_id");
        localStorage.removeItem("branch_id");
        localStorage.removeItem("role_id");
        localStorage.removeItem("specialization");
        localStorage.removeItem("specialization1");
        localStorage.removeItem("exerciseDetailsDay1")
        localStorage.removeItem("exerciseDetailsDay2")
        localStorage.removeItem("exerciseDetailsDay3")
        localStorage.removeItem("exerciseDetailsDay4")
        localStorage.removeItem("exerciseDetailsDay5")
        localStorage.removeItem("exerciseDetailsDay6")
        localStorage.removeItem("branchFilter");
        localStorage.removeItem("pathname")
        navigate(ROUTES.LOGIN)
    }

    const handleClick1 = () => {
        localStorage.removeItem("exerciseDetailsDay1");
        localStorage.removeItem("exerciseDetailsDay2");
        localStorage.removeItem("exerciseDetailsDay3");
        localStorage.removeItem("exerciseDetailsDay4");
        localStorage.removeItem("exerciseDetailsDay5");
        localStorage.removeItem("exerciseDetailsDay6");
    }

    useEffect(() => {
        if (searchStart) {
            setSearchQuery(searchTerm);
            navigate(ROUTES.CUSTOMERS);
            setSearchStart(false)
        }
        getApi()
        const storedBranch = localStorage.getItem("branchFilter");
        if (storedBranch) {
            setSelectedBranch(storedBranch);
        }
    }, [searchTerm]);

    async function getApi() {
        const company_id = localStorage.getItem("company_id")
        let result = await Services.getService(`admin/role-branch-list?id=${company_id}`);
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setBranch(result?.body?.branch)
        }
        else {
        }
    }


    useEffect(() => {
        localStorage.setItem("pathname", location.pathname)
    }, [location.pathname])

    const handleChange = (e) => {
        const branchId = e.target.value;
        localStorage.setItem("branchFilter", branchId);
        setSelectedBranch(branchId);
        let pathname = localStorage.getItem("pathname")
        if (pathname == "/customers/customer-profile" || pathname == "/customers/edit-customer-detail") {
            pathname = "/customers"
        }
        else if (pathname == "/leads/edit-lead") {
            pathname = "/leads"
        }
        else if (pathname == "/staff/edit-staff") {
            pathname = "/staff"
        }
        else {
            pathname = localStorage.getItem("pathname")
        }
        navigate(pathname)
        window.location.reload();
    }

    return (
        <>
            {/* header-section */}
            <section className='main-header main-spacing'>
                <nav className="navbar navbar-expand-lg bg-white">
                    <div className="container-fluid">
                        <a className="navbar-brand header-brand me-0" onClick={() => window.location.reload()
                        }><img src={LOGIN_LOGO} className="header-logo" /></a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse nav-top-head" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item  position-relative">
                                    <form className="d-flex search-header" >
                                        <input className="form-control ms-2 nav-input-search" type="search" placeholder="Search customers..."
                                            value={searchTerm}
                                            onChange={(e) => {
                                                setSearchTerm(e.target.value)
                                                setSearchStart(true)
                                            }} />
                                        <img src={SEARCH_ICON} style={{ cursor: 'pointer' }} className="search-icon"
                                        />
                                    </form>
                                </li>
                            </ul>

                            <ul className="navbar-nav mb-2 mb-lg-0 d-flex header-navheads">
                                {
                                    role != 1 &&
                                    <li className="nav-item mx-0 mx-lg-2">
                                        <select name='Select branch' className="form-select form-control common-input header-branch" aria-label="Default select example" value={selectedBranch}
                                            onChange={(e) => handleChange(e)} >
                                            {/* <option className="header-options" value=""> All</option> */}
                                            {
                                                branch?.map((item) => {
                                                    return (
                                                        <option key={item.id} value={item.id}>{item?.address_line_2}</option>

                                                    )
                                                })
                                            }
                                        </select>
                                    </li>

                                }

                                <li className="nav-item mx-3 mx-lg-2">
                                    <a className="nav-link header-icons" aria-current="page"><img src={NOTIFICATION_ICON} className="header-img" /></a>
                                </li>
                                <li className="nav-item mx-0 mx-lg-2">
                                    <div className="dropdown">
                                        <button type="button" className="btn leads-btn header-leads-btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><img src={PROFILE_ICON} className="header-img" /></button>
                                        <ul className="dropdown-menu header-dropdown-menu">
                                            {/* <li><a className="dropdown-item header-drop-items">Setting</a></li>
                                            <li><a className="dropdown-item header-drop-items" >Profile </a></li> */}
                                            <li><a className="dropdown-item header-drop-items" onClick={handleClick}>Log Out</a></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="collapse navbar-collapse straight-line" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0 mt-3 dash-header">
                                <li className="nav-item header-nav-item dask-link">
                                    <NavLink activeclassname="active" className="nav-link header-nav-link" aria-current="page" to={ROUTES.DASHBOARD}>Dashboard</NavLink>                                </li>
                                <li className="nav-item header-nav-item" onClick={handleClick1}>
                                    <NavLink activeclassname="active" className="nav-link header-nav-link" aria-current="page" to={ROUTES.CUSTOMERS}>Customers</NavLink>
                                </li>
                                <li className="nav-item header-nav-item" onClick={handleClick1}>
                                    <NavLink activeclassname="active" className="nav-link header-nav-link" aria-current="page" to={ROUTES.LEADES}>Leads</NavLink>
                                </li>
                                <li className="nav-item header-nav-item" onClick={handleClick1}>
                                    <NavLink activeclassname="active" className="nav-link header-nav-link" aria-current="page" to={ROUTES.STAFF}>Staff</NavLink>
                                </li>
                                <li className="nav-item header-nav-item" onClick={handleClick1}>
                                    <NavLink activeclassname="active" className="nav-link header-nav-link" aria-current="page" to={ROUTES.ATTENDENCE}>Attendence</NavLink>
                                </li>
                                <li className="nav-item header-nav-item" onClick={handleClick1}>
                                    <a className="nav-link header-nav-link" aria-current="page" >Sales</a>
                                </li>
                                <li className="nav-item header-nav-item" onClick={handleClick1}>
                                    <NavLink activeclassname="active" className="nav-link header-nav-link" aria-current="page" to={ROUTES.WORKOUT}>Workout</NavLink>
                                </li>
                                <li className="nav-item header-nav-item" onClick={handleClick1}>
                                    <NavLink to={ROUTES.EXERCISE} className="nav-link header-nav-link" aria-current="page" >Exercises</NavLink>
                                </li>
                                <li className="nav-item header-nav-item">
                                    <a className="nav-link header-nav-link" aria-current="page" >Settings</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </section>
        </>
    )
}
export default Header;