import React, { useEffect, useState } from 'react'
import { DELETE_ICON, DROP_DOWNS, EDIT_ICON, UP_ICON, WRITE_CONTENT } from '../Middleware/images'
import moment from "moment";
import Pagination from './pagination';

function Membership(props) {
  const [activeItems, setActiveItems] = useState({});

  const toggleClass = (id) => {
    setActiveItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  return (
    <>
      <div className="row h-100 align-items-center">
        <div className="col-12">
          {
            props?.membership?.length == 0 ? <>
              <div className="no-data-content text-center">
                <img src={WRITE_CONTENT} className="img-fluid" />
                <p className="no-data-text fw-500 mt-2">No History Yet</p>
              </div>
            </> : <>
              <div className="table-responsive leads-table-responsive member-table-top">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className="leads-table-head">Billing date</th>
                      <th scope="col" className="leads-table-head">valid from</th>
                      <th scope="col" className="leads-table-head">valid to</th>
                      <th scope="col" className="leads-table-head">Base amount</th>
                      <th scope="col" className="leads-table-head">discount</th>
                      <th scope="col" className="leads-table-head">net amount</th>
                      <th scope="col" className="leads-table-head">paid amount</th>
                      <th scope="col" className="leads-table-head">Balance Amount</th>
                      {/* <th scope="col" className="leads-table-head">Paid Balance Amount</th> */}
                      <th scope="col" className="leads-table-head">Payment Mode</th>
                      <th scope="col" className="leads-table-head">Transaction ID</th>
                      <th scope="col" className="leads-table-head">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      props?.membershipList?.map((item) => {
                        const discountPercentage = ((item.base_amount - item.net_amount) / item.base_amount) * 100;
                        const roundedDiscountPercentage = discountPercentage % 1 === 0 ? discountPercentage.toFixed(0) : discountPercentage.toFixed(2);
                        return (
                          <tr key={item?.id}>
                            <td className={activeItems[item.id] ? "leads-table-data leads-table-align" : "leads-table-data"}>
                              <div className='text-start'>
                                <span className='fw-600'>{item.plan_type == "1" ? "GT" : "PT"}</span>
                                <div className='d-flex gap-2 align-items-center'>
                                  {
                                    item.status == "Active" ? <div className="green-button">{item.status}</div>
                                      : <div className="red-button">{item.status}</div>
                                  }
                                  {moment(item.billing_date).format('Do MMM, YYYY')}
                                </div>

                              </div>
                            </td>
                            <td className={activeItems[item.id] ? "leads-table-data leads-table-align" : "leads-table-data"}>{moment(item.start_date).format('Do MMM, YYYY')}</td>
                            <td className={activeItems[item.id] ? "leads-table-data leads-table-align" : "leads-table-data"}>{moment(item.end_date).format('Do MMM, YYYY')}</td>
                            <td className={activeItems[item.id] ? "leads-table-data leads-table-align" : "leads-table-data"}>{`₹${item?.base_amount}`}</td>
                            <td className={activeItems[item.id] ? "leads-table-data leads-table-align" : "leads-table-data"}>
                              {discountPercentage === 0 ? "-" : `${roundedDiscountPercentage}%`}
                            </td>
                            <td className={activeItems[item.id] ? "leads-table-data leads-table-align" : "leads-table-data"}>{`₹${item?.net_amount}`}</td>
                            <td className={item?.transaction_details.length > 1 ? "leads-table-data leads-table-align" : "leads-table-data "}>
                              <div className="dropdown">
                                <a onClick={() => {
                                  if (item?.transaction_details.length > 1) {
                                    toggleClass(item.id)
                                  }
                                }} className="text-black text-start">
                                  <div className="d-flex align-items-center justify-content-between gap-2">
                                    <div>
                                      <p className={activeItems[item.id] && item?.transaction_details.length > 1 ? 'text-black fw-600' : 'text-black'}>
                                        {`₹${item?.paid_amount}`}
                                      </p>
                                      <p className={activeItems[item.id] && item?.transaction_details.length > 1 ? 'text-black fw-600' : 'text-black'}>
                                        {
                                          item?.transaction_details.length > 1 &&
                                          <>                                        {moment(item.created_at).format('Do MMM, YYYY')}
                                          </>

                                        }
                                      </p>
                                    </div>
                                    {item?.transaction_details.length > 1 && (
                                      <img src={activeItems[item.id] ? DROP_DOWNS : UP_ICON} alt="dropdown icon" />
                                    )}
                                  </div>
                                </a>
                                <div className={activeItems[item.id] ? 'd-block text-start' : 'd-none text-start'}>
                                  {item?.transaction_details.length > 1 && item?.transaction_details.map((transaction) => (
                                    <a key={transaction.id} className="d-block text-black fw-400">
                                      <p>{`₹${transaction?.paid_amount}`}</p>
                                      <p className="pay-date fw-400">{moment(transaction.created_at).format('Do MMM, YYYY')}</p>
                                    </a>
                                  ))}
                                </div>
                              </div>
                            </td>
                            {/* <td className={activeItems[item.id] ? "leads-table-data leads-table-align":"leads-table-data"}>{item?.balance_amount === "0" ? `₹${0}` : `₹${item?.balance_amount}`}</td> */}
                            <td className={activeItems[item.id] ? "leads-table-data leads-table-align" : "leads-table-data"}>
                              <span className={item.balance_amount != 0 ? 'due-balance fw-600 d-inline-block text-center mx-auto mb-2' : ' d-inline-block text-center mx-auto '}>{item?.balance_amount == null ? `₹${0}` : `₹${item?.balance_amount}`}</span>
                              {
                                item.balance_amount != 0 &&
                                <a className='pay-balance d-block fw-700 text-center mx-auto' onClick={() => props.handleBalanceOpen(item)}>Pay</a>
                              }

                            </td>
                            <td className={activeItems[item.id] ? "leads-table-data leads-table-align" : "leads-table-data"}>{item?.mode_of_payment}</td>
                            <td className={activeItems[item.id] ? "leads-table-data leads-table-align" : "leads-table-data"}>{item?.transaction_id ? item?.transaction_id : "-"}</td>
                            <td className={activeItems[item.id] ? "leads-table-align" : ""}>
                              <div className="action-btns d-flex justify-content-center text-start align-items-center gap-3">
                                <img
                                  src={DELETE_ICON}
                                  style={{ cursor: "pointer", width: "25px", height: "25px" }}
                                  onClick={() => props.handleMembershipOpen(item.id)} alt=""
                                  className="img-fluid"
                                />
                                {
                                  (item.status != 3 || item.status != "1") &&
                                  <img
                                    src={EDIT_ICON}
                                    alt=""
                                    className="img-fluid"
                                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                                    onClick={() => props.handleOpen(item.id)} />
                                }
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>

                </table>
              </div>
              {
                props?.membership?.length > 0 && <>
                  <div className="row pt-3">
                    <div className="col-6">
                      <a href=""><span className="page-entry">
                        {
                          props.total === 1 ? <>
                            Showing {props.total} entry
                          </> :
                            <>
                              Showing {props.startIndex} to {props.endIndex} of {props.total} entries
                            </>
                        }
                      </span>
                      </a>
                    </div>
                    <div className="col-6">
                      <nav aria-label="Page">
                        <ul className="pagination d-flex justify-content-end">
                          <Pagination currentPage={props.currentPage} totalPages={props.totalPages} onPageChange={props.handlePageChange} />
                        </ul>
                      </nav>
                    </div>
                  </div>
                </>
              }
            </>
          }


        </div>
      </div>

    </>
  )
}

export default Membership