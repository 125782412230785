import React, { useEffect, useState } from 'react'
import { USER_PROFILE, CROSS_BTN, CROSSED } from '../../../Middleware/images'
import { useLocation } from 'react-router-dom'
import Services from '../../../Helper/services';
import ReactLoader from '../../../Loader/react-loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
import Modals from '../../../components/modal';
import Workout from '../../../components/workout';
import Consent from '../../../components/consent';
import Membership from '../../../components/membership';
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "../../../assets/style/customerProfile.css";
import ERR_MESSAGE from '../../../Helper/error-helper';
import AboutEdit from '../../../components/aboutedit';
import uploadFileToS3 from '../../../Helper/fileUpload';
import moment from 'moment';
import DatePickerValue from '../../../components/date-picker';
import Client from '../../../components/client';
import Bills from '../../../components/bills';
import Freeze from '../../../components/freeze';
import { format, differenceInDays, isToday, isTomorrow } from 'date-fns';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    height: 650,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    border: "1px solid #6ED7EF",
    p: 0,
    outline: 0,
    overflow: "hidden",
    overflowY: "scroll"
};

const styleMembership = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    border: "1px solid #6ED7EF",
    p: 0,
    outline: 0,
    textAlign: "center",
};

const currentDate = new Date().toISOString().split('T')[0];
const EditCustomer = () => {
    const [freeze, setFreeze] = useState([])
    const [deltId, setDeltId] = useState(null)
    const [userChangedInfo, setUserChangedInfo] = useState(false);
    const [error1, setError1] = useState('');
    const [bill, setBill] = useState([])
    const [openBill, setOpenBill] = useState("")
    const [gymDetail, setGymDetail] = useState({})
    const [startDate, setStartDate] = useState("")
    const [workoutDetail, setWorkoutDetail] = useState([]);
    const [inputValue, setInputValue] = useState('')
    const [excerciseList, setExcerciseList] = useState([]);
    const [excerciseList1, setExcerciseList1] = useState([]);
    const [error, setError] = useState('');
    const [filteredExercises, setFilteredExercises] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [noResults, setNoResults] = useState(false);
    const [client, setClient] = useState([]);
    const [openMembership, setOpenMembership] = useState("")
    const [trainerList, setTrainerList] = useState([]);
    const [branch, setBranch] = useState([]);

    const handleInputChange = (event) => {
        setError('');
        setError1("")
        const value = event.target.value;
        setInputValue(value);
        const filteredList = excerciseList1?.filter((item) =>
            item?.workout_name.toLowerCase().includes(value.toLowerCase())
        );
        if (filteredList.length === 0 && value !== '') {
            setNoResults(true);
        } else {
            setNoResults(false);
        }
        setFilteredExercises(filteredList);
    };

    const handleCheckboxChange = (item) => {
        setError("")
        if (selectedValue && selectedValue.id !== item.id) {
            setError('Only one workout can be selected');
        } else {
            setError('');
            setSelectedValue(selectedValue === item ? null : item);
        }
    };


    const handleCrossClick = () => {
        setSelectedValue(null);
        setError('');
    };
    const [customerList, setCustomerList] = useState([]);
    const [membership, setMembership] = useState([])
    // const [aboutButton, setAboutButton] = useState(true);
    const [editButton, setEditButton] = useState(false);
    const [membershipButton, setMembershipButton] = useState(false);
    const [open, setOpen] = useState(false);
    const location = useLocation();
    const [openDelete, setOpenDelete] = useState(false);
    const [errors, setErrors] = useState("");
    const [loading, setLoading] = useState(false);

    const [info, setInfo] = useState({
        profile_pic: "",
        name: "",
        whatsapp_no: "",
        alternate_no: "",
        date_of_birth: "",
        email: "",
        height: "",
        weight: "",
        gender: "",
        address_1: "",
        registration_id: "",
        activity_level: "",
        prospect_source: "",
        training_type: "",
        goal: "",
        sales_person: "",
        mobile_number: "",
        refer_by: "",
        branch_id: "",

    });
    const [membershipId, setMemebrshipId] = useState(null)

    const [data1, setData1] = useState({
        discount_type: "",
        discount: "",
        base_amount: "",
        trainer_id: "",
        net_amount: "",
        paid_amount: "",
        balance_amount: "",
        month: "",
        days: "",
        start_date: currentDate,
        end_date: "",
        mode_of_payment: "",
        transaction_id: "",
        plan_type: "",
        paid_balance_amout: "",
        bill_date: ""

    })
    const [symbol, setSymbol] = useState(false)
    const [total, setTotal] = useState()
    const [total1, setTotal1] = useState()
    const [total2, setTotal2] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 8;
    const startIndex = Math.max((currentPage - 1) * pageSize + 1, 1);
    const endIndex = Math.min(startIndex + pageSize - 1, total);
    const membershipList = membership;
    const totalPages = Math.ceil(total / pageSize);
    const endIndex1 = Math.min(startIndex + pageSize - 1, total1);
    const totalPages1 = Math.ceil(total1 / pageSize);
    const endIndex2 = Math.min(startIndex + pageSize - 1, total2);
    const totalPages2 = Math.ceil(total2 / pageSize);
    const [dataBalance, setDataBalance] = useState({
        balance_amount: "",
        mode_of_payment: "",
        transaction_id: ""
    })

    const handleChangeBalance = (e) => {
        setErrors("")
        setDataBalance({
            ...dataBalance,
            [e.target.name]: e.target.value
        })
    }


    const [openBalance, setBalanceOpen] = useState(false);

    const handleBalanceOpen = (item) => {
        setBalanceOpen(true)
        setMemebrshipId(item.id)
        getMembershipDetail(item.id, "balance")
    }

    const handleBalanceClose = () => {
        setBalanceOpen(false);
        setErrors("")
        setMemebrshipId(null)

    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
        getMembershipApi(page)
        getBillApi(page)
    };
    const handleBillOpen = (id) => {
        setOpenBill(true);
        setDeltId(id)
    };

    const handleBillClose = () => {
        setOpenBill(false)
    }
    async function getBranchApi() {
        const company_id = localStorage.getItem("company_id")
        let result = await Services.getService(`admin/role-branch-list?id=${company_id}`);
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setBranch(result?.body?.branch)
        }
        else {
        }
    }
    useEffect(() => {
        getBranchApi()
    }, [])

    const [info1, setInfo1] = useState({
        name: info.name,
        whatsapp_no: info.whatsapp_no,
        email: info.email,
        sales_person: info.sales_person,
        registration_id: info.registration_id,
    });

    const [assign_Date, setAssignDate] = useState("");
    const [activeDay, setActiveDay] = useState('Mon');
    const handleOpen = (id) => {
        setSymbol(false)
        setOpen(true)
        setMemebrshipId(id)
        getMembershipDetail(id)
    }

    const handleValidationBalance = () => {
        let errors = {};
        let formIsValid = true;
        const { balance_amount,
            mode_of_payment,

        } = dataBalance
        if (!balance_amount || balance_amount.length === 0) {
            formIsValid = false;
            errors["balance_amount"] = ERR_MESSAGE.BALANCE_AMOUNT;
        }

        if (!mode_of_payment || mode_of_payment.length === 0) {
            formIsValid = false;
            errors["mode_of_payment"] = ERR_MESSAGE.EMPTY_MODEOFPAYMENT;
        }

        setErrors(errors);
        return formIsValid;

    }
    async function postBlanceApi(e) {
        e.preventDefault()
        if (handleValidationBalance()) {
            let data = JSON.stringify({
                "user_id": location.state.id,
                "membership_id": membershipId,
                "paid_amount": dataBalance.balance_amount,
                "mode_of_payment": dataBalance.mode_of_payment,
                "transaction_id": dataBalance.transaction_id
            });

            setLoading(true);
            const result = await Services.postService("admin/pay-membership-due-amount", data);
            if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                toast.success(result.message);
                getApi()
                getBillApi(currentPage)
                getMembershipApi(currentPage)
                setBalanceOpen(false)
            }
            else {
                setLoading(false);
            }
        }
    }
    async function getMembershipDetail(id, balance) {
        setLoading(true)
        let result = await Services.getService(`admin/get-membership-details?id=${id}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            if (result?.body === null) {
                const updatedData = {
                    ...result.body,
                    days: 0,
                    balance_amount: 0,
                    trainer_id: result?.body?.trainer_name,
                    // bill_date: result.body.billing_date

                };
                setData1(updatedData);
            }
            else {
                const updatedData = {
                    ...result.body,
                    days: result.body.days || 0,
                    balance_amount: result.body.balance_amount || 0,
                    bill_date: result.body.billing_date

                };
                setData1(updatedData);
            }
            if (balance) {
                setDataBalance({
                    ...dataBalance,
                    balance_amount: result?.body?.balance_amount,
                    mode_of_payment: "",
                    bill_date: result.body.billing_date

                })
            }

            setLoading(false)
        }
        else {
            setLoading(false);
        }
    }

    const handleClose = () => {
        setOpen(false);
        setErrors("")
        setMemebrshipId(null)
        setData1({
            ...data1,
            discount_type: "",
            discount: "",
            base_amount: "",
            net_amount: "",
            paid_amount: "",
            balance_amount: "",
            month: "",
            days: "",
            start_date: "",
            end_date: "",
            mode_of_payment: "",
            transaction_id: ""
        })
    };

    const handleDeleteOpen = () => {
        setOpenDelete(true);
    };
    const handleDeleteClose = () => {
        setInputValue("")
        setSelectedValue("")
        setOpenDelete(false)
    }

    const setDateOfBirth = (newDate) => {
        setInfo((prevFields) => ({
            ...prevFields,
            date_of_birth: newDate,
        }));
    };
    const [referSymbol, setReferSymbol] = useState(info.refer_by != 0 ? true : false)

    const handleChange = (e) => {
        setErrors("")
        const regex = /^[0-9]*$/;
        const name = e.target.name
        const value = e.target.value
        if (name === 'whatsapp_no' && !regex.test(value)) {
            return;
        }
        if (name === 'alternate_no' && !regex.test(value)) {
            return;
        }
        if (name === "refer_by") {
            setReferSymbol(false)
            setInfo((prevInfo) => ({
                ...prevInfo,
                [name]: value
            }));
        }
        setInfo({
            ...info, [name]: value
        })
    }
    const calculateNetAmount = () => {
        const baseAmount = Number(data1?.base_amount) || 0;
        const discount = Number(data1?.discount) || 0;

        if (data1?.discount_type === '1') {
            const discountAmount = (baseAmount * discount) / 100;
            const netAmount = baseAmount - discountAmount;
            setData1((prevInfo) => ({ ...prevInfo, net_amount: netAmount, paid_amount: netAmount }));
        }
        else if (data1?.discount_type === '2') {
            const discountAmount = data1.discount
            const netAmount = baseAmount - discountAmount;
            setData1((prevInfo) => ({ ...prevInfo, net_amount: netAmount, paid_amount: netAmount }));
        }
        else {
            setData1((prevInfo) => ({ ...prevInfo, net_amount: data1?.base_amount, paid_amount: data1?.base_amount }));
        }
    };

    const calculateBalance = () => {
        const netAmount = Number(data1?.net_amount) || 0;
        const paid_amount = Number(data1?.paid_amount) || 0;
        const balance_amount = netAmount - paid_amount;
        setData1((prevInfo) => ({ ...prevInfo, balance_amount: balance_amount }));
    };

    useEffect(() => {
        if (symbol) {
            calculateNetAmount();
        }
    }, [data1?.base_amount, data1?.discount_type, data1?.discount]);

    useEffect(() => {
        if (symbol) {
            calculateBalance();
        }
    }, [data1?.net_amount, data1?.paid_amount, data1?.base_amount]);

    useEffect(() => {
        if (symbol) {
            setData1((prevInfo) => ({ ...prevInfo, paid_amount: data1?.net_amount }));
        }
    }, [data1?.net_amount]);

    const handleChange2 = (e) => {
        setErrors('');
        setSymbol(true)
        const name = e.target.name;
        let value = e.target.value;
        if ((name === 'days' || name === 'month' || name === "base_amount" || name === 'net_amount' || name === 'paid_amount') && parseFloat(value) < 0) {
            value = 0;
        }
        if (name === 'month') {
            value = value.replace(/\./g, ''); 
        }
        if (name === 'month' && parseFloat(value) > 12) {
            value = 0;
        }
        if (name === 'discount') {
            const baseAmount = parseFloat(data1?.base_amount);
            value = Math.min(parseFloat(value), baseAmount);
        }
        if (name === 'net_amount') {
            const baseAmount = parseFloat(data1?.base_amount);
            value = Math.min(parseFloat(value), baseAmount);
        }
        if (name === 'paid_amount') {
            const netAmount = parseFloat(data1?.net_amount);
            value = Math.min(parseFloat(value), netAmount);
        }
        setData1((prevInfo) => ({
            ...prevInfo,
            [name]: value,
        }));
        if (name === 'discount_type' && value === '2') {
            setData1((prevInfo) => ({
                ...prevInfo,
                discount: '',
            }));
        }

        if (name === 'base_amount' || name === 'discount_type' || name === 'discount') {
            calculateNetAmount();
        }

        if (name === 'net_amount' || name === 'paid_amount') {
            calculateBalance();
        }
        if (name === 'discount_type' && value === '1') {
            if (name === 'discount' && value > 100) {
                setData1((prevInfo) => ({
                    ...prevInfo,
                    discount: 100,
                }));
            }
        }

        if (name === "month" || name === "days") {
            setUserChangedInfo(true);
        }
    };


    useEffect(() => {
        if (userChangedInfo) {
            handleStart(data1?.start_date ? data1.start_date : new Date().toISOString().split('T')[0]);
            setUserChangedInfo(false);
        }
    }, [data1?.month, data1?.days, userChangedInfo]);

    function generateDayArray(startingDay) {
        const daysOfWeekExceptSunday = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        const startingIndex = daysOfWeekExceptSunday.indexOf(startingDay);
        if (startingIndex === -1) {
            throw new Error('Invalid starting day provided.');
        }
        const daysArray = [];
        for (let i = startingIndex; i < daysOfWeekExceptSunday.length; i++) {
            daysArray.push(daysOfWeekExceptSunday[i]);
        }
        for (let i = 0; i < startingIndex; i++) {
            daysArray.push(daysOfWeekExceptSunday[i]);
        }
        const daysWithValues = daysArray.map((day, index) => {
            const dayValue = (index + 1) % 7 === 0 ? 6 : (index + 1) % 7;
            return { [day]: `day${dayValue}` };
        });
        return daysWithValues;
    }

    const handleDayClick = (day) => {
        setActiveDay(day)
        if (assign_Date) {
            const assignDate = new Date(assign_Date);
            const daysOfWeekExceptSunday = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            const currentDayIndex = assignDate.getDay();
            const startingDay = daysOfWeekExceptSunday[currentDayIndex - 1];
            const daysArrayWithValues = generateDayArray(startingDay);
            const convertedObject = daysArrayWithValues.reduce((acc, obj) => {
                const key = Object.keys(obj)[0];
                acc[key] = obj[key];
                return acc;
            }, {});
            const currentSunDayValue = ""
            const currentDayValue = convertedObject[day];
            if (day === "Sun") {
                getDateApi(currentSunDayValue)
            }
            else {
                getDateApi(currentDayValue)
            }
        }
        else {
            getDateApi()
        }
    };

    async function getDateApi(currentDayValue) {
        setLoading(true);
        let result = await Services.getService(`workout/workout-plan?user_id=${location.state.id}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setWorkoutDetail(result?.body);
            setAssignDate(result?.body?.assign_date)
            if (currentDayValue !== undefined) {
                setExcerciseList(result.body.Days[currentDayValue])
            } setLoading(false);
        }
        else {
            setLoading(false);
        }
    }

    const handleValidation1 = () => {
        let errors = {};
        let formIsValid = true;
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        const emojiSymbolRegex = /[^\p{L}\s]/u;
        const whatsappRegex = /^\d+$/;
        const { name, whatsapp_no, mobile_number, alternate_no, date_of_birth, email, branch_id } = info
        if (!name || name?.trim().length === 0) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.EMPTY_FULLNAME;
        }
        else if (name.length < 3 && name.length > 1) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_FULLNAME_MIN;
        }
        else if (name.length > 30) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_FULLNAME_MAX;
        }
        else {
            const regex = /^[a-zA-Z\s]*$/;
            if (!regex.test(name) || emojiSymbolRegex.test(name)) {
                formIsValid = false;
                errors["name"] = ERR_MESSAGE.INVALID_FULLNAME;
            }
        }
        if (!whatsapp_no || whatsapp_no?.trim().length === 0) {
            formIsValid = false;
            errors["whatsapp_no"] = ERR_MESSAGE.EMPTY_MOBILE
        }
        else if (!whatsappRegex.test(whatsapp_no) && whatsapp_no.length > 1) {
            formIsValid = false;
            errors["whatsapp_no"] = ERR_MESSAGE.INVALID_MOBILE
        }
        else if (whatsapp_no.length < 10 && whatsapp_no.length > 1) {
            formIsValid = false;
            errors["whatsapp_no"] = ERR_MESSAGE.INVALID_MOBILE_MIN
        }

        if (!whatsappRegex?.test(alternate_no) && alternate_no?.length > 1) {
            formIsValid = false;
            errors["alternate_no"] = ERR_MESSAGE.INVALID_MOBILE
        }
        else if (alternate_no?.length < 10 && alternate_no?.length > 1) {
            formIsValid = false;
            errors["alternate_no"] = ERR_MESSAGE.INVALID_MOBILE_MIN
        }
        if (!mobile_number || mobile_number?.trim().length === 0) {
            formIsValid = false;
            errors["mobile_number"] = ERR_MESSAGE.EMPTY_MOBILE
        }
        else if (!whatsappRegex?.test(mobile_number) && mobile_number?.length > 1) {
            formIsValid = false;
            errors["mobile_number"] = ERR_MESSAGE.INVALID_MOBILE
        }
        else if (mobile_number?.length < 10 && mobile_number?.length > 1) {
            formIsValid = false;
            errors["mobile_number"] = ERR_MESSAGE.INVALID_MOBILE_MIN
        }
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const parts = date_of_birth.split("-");
        const year = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10) - 1;
        const day = parseInt(parts[2], 10);
        const dob = new Date(year, month, day);
        dob.setHours(0, 0, 0, 0);
        if (dob.getTime() >= today.getTime()) {
            formIsValid = false;
            errors["date_of_birth"] = ERR_MESSAGE.INVALID_DATEOFBIRTH;
        }
        if (reg.test(email.toLowerCase()) === false && email.length > 1) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_INVALID;
        }
        if (!branch_id || branch_id.length === 0) {
            formIsValid = false;
            errors["branch_id"] = ERR_MESSAGE.EMPTY_BRANCH;
        }
        setErrors(errors);
        return formIsValid;

    }

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        const { base_amount,
            month,
            paid_amount,
            net_amount,
            mode_of_payment,
            trainer_id,
            bill_date
        } = data1
        if (data1.plan_type === '2') {
            if (!trainer_id || trainer_id === undefined) {
                formIsValid = false;
                errors["trainer_id"] = ERR_MESSAGE.EMPTY_TRAINER
            }
        }
        if (!base_amount || base_amount.length === 0) {
            formIsValid = false;
            errors["base_amount"] = ERR_MESSAGE.EMPTY_BASEAMOUNT;
        }
        if (!net_amount || net_amount.length === 0) {
            formIsValid = false;
            errors["net_amount"] = ERR_MESSAGE.EMPTY_NETAMOUNT;
        }

        if (!paid_amount || paid_amount.length === 0) {
            formIsValid = false;
            errors["paid_amount"] = ERR_MESSAGE.EMPTY_PAIDAMOUNT;
        }
        if (!month) {
            formIsValid = false;
            errors["month"] = ERR_MESSAGE.EMPTY_PLANPERIOD;
        }

        if (!bill_date || bill_date.length === 0) {
            formIsValid = false;
            errors["bill_date"] = ERR_MESSAGE.EMPTY_DATE;
        }

        if (!mode_of_payment || mode_of_payment.length === 0) {
            formIsValid = false;
            errors["mode_of_payment"] = ERR_MESSAGE.EMPTY_MODEOFPAYMENT;
        }
        setErrors(errors);
        return formIsValid;
    }

    async function postAssignApi(e) {
        e.preventDefault();
        let errorMessages = [];

        if (!selectedValue) {
            errorMessages.push("Select workout");
        }

        if (!startDate) {
            errorMessages.push("Select start date");
        } else {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            const selectedDate = new Date(startDate);
            selectedDate.setHours(0, 0, 0, 0);

            if (selectedDate < today) {
                errorMessages.push("Enter valid date");
            }
        }

        if (errorMessages.length > 0) {
            if (errorMessages.length === 3) {
                setError("Select workout");
                setError1("Select start date");
            } else {
                if (errorMessages.includes("Select workout")) {
                    setError("Select workout");
                } else {
                    setError("");
                }
                if (errorMessages.includes("Select start date")) {
                    setError1("Select start date");
                }
                else if (errorMessages.includes("Enter valid date")) {
                    setError1("Enter valid date");
                }
            }
            return;
        }

        let data = JSON.stringify({
            "workout_id": selectedValue?.id,
            "user_id": location?.state?.id,
            "assign_date": startDate
        });

        setLoading(true);
        try {
            const result = await Services.postService("workout/assign-workout", data);
            setLoading(false);

            if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                toast.success(result.message);
                handleDeleteClose();
                getApi();
                getMembershipApi(currentPage);
                getBillApi(currentPage)
            } else {
                toast.error(result.message);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    async function getApiCustomer() {
        setLoading(true)
        let result = await Services.getService(`admin/get-customers?company_id=${localStorage.getItem("company_id")}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setCustomerList(result?.body?.data);
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }
    async function getApiClient() {
        setLoading(true)
        let result = await Services.getService(`admin/reference-clients?id=${location.state.id}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setClient(result?.body);
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }

    useEffect(() => {
        getApiCustomer()
        getApiClient()
    }, [])

    const setBillSince = (newDate) => {
        setData1((prevFields) => ({
            ...prevFields,
            bill_date: newDate,
        }));
    };

    async function postApi(e) {
        e.preventDefault()
        if (handleValidation1()) {
            let data = JSON.stringify({
                "id": location.state.id,
                "company_id": localStorage.getItem("company_id"),
                // "branch_id": localStorage.getItem("branch_id"),
                "profile_pic": info?.profile_pic,
                "name": info?.name,
                "mobile_number": info.mobile_number,
                "refer_by": info.refer_by,
                "whatsapp_no": info?.whatsapp_no,
                "alternate_no": info?.alternate_no,
                "date_of_birth": info?.date_of_birth,
                "email": info?.email,
                "height": info?.height,
                "weight": info?.weight,
                "gender": info?.gender,
                "address_1": info?.address_1,
                "registration_id": info?.registration_id,
                "activity_level": info?.activity_level,
                // "prospect_source": info?.prospect_source,
                "training_type": info?.training_type,
                "goal": info?.goal,
                "sales_person": info?.sales_person,
                // "trainer_id": "1",
                'branch_id': info.branch_id

            });

            setLoading(true);
            const result = await Services.postService("admin/edit-customer", data);
            if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                toast.success(result.message);
                getApi()
                if (info.refer_by != 0) {
                    setReferSymbol(true)
                }
                else {
                    setReferSymbol(false)
                }
            }
            else {
                setLoading(false);
                toast.error(result.message);
            }
        }
    }

    async function postMembershipApi(e) {
        e.preventDefault()
        if (handleValidation()) {
            let data = JSON.stringify({
                "plan_type": data1.plan_type ? data1.plan_type : "1",
                "user_id": location.state.id,
                "discount_type": data1.discount_type,
                "discount": data1.discount,
                "base_amount": data1.base_amount,
                "net_amount": data1.net_amount,
                "paid_amount": data1.paid_amount,
                "balance_amount": data1.balance_amount ? data1.balance_amount : "0",
                "month": data1.month,
                "days": data1.days ? data1.days : "0",
                "start_date": data1.start_date ? new Date(data1.start_date).toISOString().split("T")[0] : currentDate,
                "end_date": data1.end_date,
                "mode_of_payment": data1.mode_of_payment,
                "transaction_id": data1.transaction_id,
                "trainer_id": data1.trainer_id,
                "billing_date": data1.bill_date
            });
            setLoading(true);
            const result = await Services.postService("admin/purchase-membership", data);
            if (result.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                toast.success(result.message);
                handleClose()
                getApi()
                getMembershipApi(currentPage)
                getBillApi(currentPage)
                setData1({
                    ...data1,
                    discount_type: "",
                    discount: "",
                    base_amount: "",
                    net_amount: "",
                    paid_amount: "",
                    balance_amount: "",
                    month: "",
                    days: "",
                    start_date: "",
                    end_date: "",
                    mode_of_payment: "",
                    transaction_id: ""
                })
            }
            else {
                setLoading(false);
                toast.error(result.message);
            }
        }
    }

    async function EditMembershipApi(e, id) {
        e.preventDefault()
        let data = JSON.stringify({
            "discount_type": data1.discount_type,
            "plan_type": data1.plan_type,
            "discount": data1.discount,
            "base_amount": data1.base_amount,
            "net_amount": data1.net_amount,
            "paid_amount": data1.paid_amount,
            "balance_amount": data1.balance_amount ? data1.balance_amount : "0",
            "month": data1.month,
            "days": data1.days ? data1.days : "0",
            "start_date": data1.start_date ? new Date(data1.start_date).toISOString().split("T")[0] : currentDate,
            "end_date": data1.end_date,
            "mode_of_payment": data1.mode_of_payment,
            "transaction_id": data1.transaction_id,
            "trainer_id": data1.trainer_id,
            "billing_date": data1.bill_date

        });
        setLoading(true);
        const result = await Services.postService(`admin/edit-membership-details?id=${id}`, data);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setLoading(false);
            toast.success(result.message);
            handleClose()
            getApi()
            getMembershipApi(currentPage)
            getBillApi(currentPage)
            setData1({
                ...data1,
                discount_type: "",
                discount: "",
                base_amount: "",
                net_amount: "",
                paid_amount: "",
                balance_amount: "",
                month: "",
                days: "",
                start_date: "",
                end_date: "",
                mode_of_payment: "",
                transaction_id: ""
            })
        }
        else {
            setLoading(false);
            toast.error(result.message);
        }
    }
    const handleMembershipOpen = (id) => {
        setOpenMembership(true);
        setDeltId(id)
    };

    const handleMembershipClose = () => {
        setOpenMembership(false)
    }
    async function getMembershipDeleteAPI() {
        setLoading(true);
        let result = await Services.getService(`admin/delete-membership?id=${deltId}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setLoading(false);
            handleMembershipClose();
            toast.success(result.message)
            getMembershipApi(currentPage)
            getBillApi(currentPage)

        } else {
            setLoading(false);
            toast.error(result.message)
        }
    }
    async function getBillDeleteAPI() {
        setLoading(true);
        let result = await Services.getService(`admin/delete-bill?id=${deltId}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setLoading(false);
            handleBillClose();
            toast.success(result.message)
            getMembershipApi(currentPage)
            getBillApi(currentPage)
        } else {
            setLoading(false);
            toast.error(result.message)
        }
    }
    async function getBillApi(pageNumber) {
        setLoading(true)
        let result = await Services.getService(`admin/bill-listing?id=${location?.state?.id}&offset=${pageNumber}&limit=${8}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setBill(result?.body.data);
            setTotal1(result.body.total)
            setGymDetail(result.body.gym_details)
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }
    async function getFreezeApi(pageNumber) {
        setLoading(true)
        let result = await Services.getService(`admin/bill-listing?id=${location?.state?.id}&offset=${pageNumber}&limit=${8}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setFreeze(result?.body.data);
            setTotal2(result.body.total)
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }
    const fileChange1 = (e) => {
        setErrors("");
        if (e.target.files.length === 0) {
            return;
        }
        const file = e.target.files[0];
        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
                const formData = new FormData();
                formData.append("image", file);
                const imageURL = URL.createObjectURL(file);
                setInfo({
                    ...info,
                    profile_pic: imageURL
                })
                fileUpload(file)
            }
        }
    };

    const fileUpload = async (file) => {
        try {
            if (file) {
                const fileUrl = await uploadFileToS3(file);
                setInfo({
                    ...info,
                    profile_pic: fileUrl
                })
            }
        }
        catch (error) {
        }
    };

    const currentDateInfo = new Date();
    async function getApi() {
        setLoading(true)
        let result = await Services.getService(`admin/customer-profile?id=${location?.state?.id}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setInfo(result?.body);
            if (result.body.refer_by != 0) {
                setReferSymbol(true)
            }
            else {
                setReferSymbol(false)
            }
            setInfo1(result?.body);
            setLoading(false)
        }
        else {
            setLoading(false);
        }
    }

    async function getTrainerApi() {
        setLoading(true)
        let result = await Services.getService(`admin/trainer-list?branch_id=${localStorage.getItem("branchFilter")}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            const filteredTrainer = result.body.data.filter(item => item.role_id == 3)
            setTrainerList(filteredTrainer);
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }


    useEffect(() => {
        getApi()
        getMembershipApi(currentPage)
        getDateApi()
        getWorkoutApi()
        getBillApi(currentPage)
        getTrainerApi()

    }, [])

    const handleTabClick = (tabName) => {
        if (tabName === 'About') {
            setMembershipButton(false)
            // setAboutButton(true);
            setEditButton(false)
        } else if (tabName === 'Membership') {
            setMembershipButton(true)
            // setAboutButton(false);
            setEditButton(false)
        }
        else if (tabName === "Biils") {
            setMembershipButton(false)
            // setAboutButton(false);
            setEditButton(false)
        }
        else if (tabName === 'Workouts') {
            // setAboutButton(false);
            setMembershipButton(false)
            setEditButton(true)
            if (assign_Date) {
                const assignDate = new Date(assign_Date);
                const daysOfWeekExceptSunday = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                const currentDayIndex = assignDate.getDay();
                const startingDay = daysOfWeekExceptSunday[currentDayIndex - 1];
                const daysArrayWithValues = generateDayArray(startingDay);
                const convertedObject = daysArrayWithValues.reduce((acc, obj) => {
                    const key = Object.keys(obj)[0];
                    acc[key] = obj[key];
                    return acc;
                }, {});
                const currentDayValue = convertedObject["Mon"];
                getDateApi(currentDayValue)
            }
        }
        else if (tabName === 'consent') {
            // setAboutButton(false);
            setMembershipButton(false)
            setEditButton(false)

        }
    };

    const [selectedFile, setSelectedFile] = useState([]);
    const [previewUrl, setPreviewUrl] = useState([]);
    const fileChange = (e) => {
        const files = Array.from(e.target.files);
        setSelectedFile((selectedFile) => [...selectedFile, ...files]);
        const urls = files.map((file) => URL.createObjectURL(file));
        setPreviewUrl((previewUrl) => [...previewUrl, ...urls]);
    };

    const handleDelete = (index) => {
        const newSelectedFile = [...selectedFile]
        const newPreviewUrl = [...previewUrl]
        newSelectedFile.splice(index, 1);
        newPreviewUrl.splice(index, 1);
        setSelectedFile([...newSelectedFile]);
        setPreviewUrl([...newPreviewUrl]);
    }
    function calculateDaysLeft(startDate, endDate, createdAt, planDays) {
        const currentDate = new Date();
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        // const created_at = new Date(createdAt);

        if (endDate) {
            if (currentDate >= endDateObj) {
                return 0;
            }

            const timeDifference = endDateObj - currentDate;
            const totalDuration = Math.ceil((endDateObj - startDateObj) / (1000 * 60 * 60 * 24)) + 1;

            let daysLeft = currentDate < startDateObj
                ? "Upcoming Plan"
                : Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

            // Ensure daysLeft is a valid number or 0
            if (typeof daysLeft === 'number' && isNaN(daysLeft)) {
                daysLeft = 0;
            }

            if (planDays) {
                return `${daysLeft} ${currentDate < startDateObj ? '' : `${daysLeft === 0 ? " " : daysLeft === 1 ? 'day left' : 'days left'} `}`;
            } else {
                return normalizeToRange(daysLeft, totalDuration);
            }
        } else {
            return 0;
        }
    }

    function normalizeToRange(value, max) {
        let result = (value / max) * 100;
        if (result === 0 || isNaN(result) || result === Infinity || result === undefined || result === null) {
            return 0;
        } else {
            return result;
        }
    }

    async function getMembershipApi(pageNumber) {
        setLoading(true)
        let result = await Services.getService(`admin/get-membership-history?id=${location?.state?.id}&offset=${pageNumber}&limit=${8}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setMembership(result?.body.data);
            setTotal(result.body.total)
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }

    function formatRegistrationID(registrationId) {
        const idString = registrationId?.toString();
        const numberOfZeros = 4 - idString?.length;
        const formattedID = '0'.repeat(numberOfZeros) + idString;
        return formattedID;
    }

    async function getWorkoutApi() {
        setLoading(true)
        let result = await Services.getService("workout/workouts-listing");
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            setExcerciseList1(result?.body?.data);
            setLoading(false);
        }
        else {
            setLoading(false);
        }
    }

    const handleStart = (newDate) => {
        if (data1.month > 0 || data1.days > 0) {
            const startDate = new Date(newDate) || currentDate;
            const month = parseInt(data1?.month || 0);
            const days = parseInt(data1?.days || 0);
            const totalDays = month * 29 + days;
            const endDate = new Date(startDate);
            endDate.setDate(startDate.getDate() + totalDays);
            const formattedEndDate = endDate.toISOString().split('T')[0];
            setData1((prevFields) => ({
                ...prevFields,
                start_date: newDate,
                end_date: formattedEndDate,
            }));
        }
        else {
            setData1((prevFields) => ({
                ...prevFields,
                start_date: newDate,
                end_date: "",
            }));
        }
    };

    const setMemberSince = (newDate) => {
        setData1((prevFields) => ({
            ...prevFields,
            end_date: newDate,
        }));
    };
    const getUserStatus = (endDate) => {
        const currentDate = new Date();
        const endDateParsed = new Date(endDate);
        const daysLeft = differenceInDays(endDateParsed, currentDate);
        const daysSinceExpiration = differenceInDays(currentDate, endDateParsed);

        if (daysLeft > 7) {
            return { status: 'Active', message: 'Active' };
        } else if (isToday(endDateParsed)) {
            return { status: 'Expired', message: 'Expires Today' };
        } else if (isTomorrow(endDateParsed)) {
            return { status: 'Expired', message: 'Expires Tomorrow' };
        } else if (daysLeft > 0 && daysLeft <= 7) {
            return { status: 'Warning', message: `Expires on ${format(endDateParsed, 'd MMM')}` };
        } else if (daysSinceExpiration <= 7 && daysSinceExpiration > 0) {
            return { status: 'Expired', message: `Expired on ${format(endDateParsed, 'd MMM')}` };  // Shows day and month
        } else {
            return { status: 'Expired', message: 'Expired' };
        }
    };

    return (
        <div>
            {loading &&
                <ReactLoader />
            }
            <ToastContainer />
            <Modals />
            <section className="bg-white p-2 m-3">
                <div className="container-fluid">
                    <div className="row border-bottom pb-4 pt-4 pt-lg-0">
                        <div className="col-xl-4">
                            <div className="profile-main text-black">
                                <div className="profile d-flex justify-content-center align-items-center inpuprofle-parent">
                                    <img src={info?.profile_pic === "" ? USER_PROFILE : info?.profile_pic} className={info?.profile_pic === "" ? "img-fluid" : "img-preview-customer"} alt="" />

                                    <input type="file" className="input-profile" id="image" accept=".jpg, .jpeg, .png" onChange={fileChange1} />
                                </div>
                                <div className="profile-text mt-4 mt-sm-0">
                                    <h4 className='mb-1'>{info1?.name}</h4>
                                    <p className="d-flex align-items-center">
                                        {info.status != "Active" ?
                                            <div className="red-dot"><span className="red-blink"></span></div> : getUserStatus(info?.end_date).status === 'Active' ? <div className="green-dot"><span className="green-blink"></span></div> : <div className="red-dot"><span className="red-blink"></span></div>}
                                        <span>

                                            {
                                                info.start_date != null ?
                                                    info.status : 'Buy Plan'
                                            }
                                        </span>
                                    </p>
                                    <div className="d-flex align-items-center gap-3 justify-content-between mt-3">
                                        <span className="f16-size fw-400">Plan Days</span>
                                        <span className="f10-size fw-500">
                                            {info?.end_date != null && currentDateInfo >= new Date(info?.end_date) ? "Plan Ended" : calculateDaysLeft(info?.start_date, info?.end_date, info?.plan_purchase_date, "planDays")}
                                        </span>
                                    </div>
                                    <div className="custom-range">
                                        <input type="range" value={calculateDaysLeft(info?.start_date, info?.end_date, info?.plan_purchase_date)} min="0" max="100" step="1" />
                                    </div>
                                    <div className="mainDiv">
                                        <span className="f14-size fw-500 text-gray">Member since:
                                            <span className="f17-size text-black px-2">{moment(info?.created_at).format('Do MMM, YYYY')} </span>  </span>                                    </div>
                                    {/* <ul className="social-links d-flex justify-content-around align-items-center w-100  mt-3">
                                        <li><img src={WHATSAPP} className=" " /></li>
                                        <li><img src={MAIL} className=" " /></li>
                                        <li><img src={CAMERA} className=" " /></li>
                                        <li><img src={MESSAGE} className=" "
                                        //  data-bs-toggle="modal" data-bs-target="#message" 
                                        /></li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 mt-4 mt-xl-0">
                            <div className="row">
                                <div className="col-lg-5 text-black">
                                    <div className="profile-details w-100 d-flex align-items-baseline mb-3">
                                        <div className="profile-label w-35">
                                            <p className="f14-size fw-500 text-gray">Client Code</p>
                                        </div>
                                        <div className="w-10">:</div>
                                        <div className="profile-input w-55">
                                            <p className="f14-size  fw-500 ">{info1?.registration_id}</p>
                                        </div>
                                    </div>
                                    <div className="profile-details w-100 d-flex align-items-baseline mb-3">
                                        <div className="profile-label w-35">
                                            <p className="f14-size fw-500 text-gray">Phone </p>
                                        </div>
                                        <div className="w-10">:</div>
                                        <div className="profile-input w-55">
                                            <p className="f14-size  fw-500 ">{info1?.whatsapp_no}</p>
                                        </div>
                                    </div>
                                    <div className="profile-details w-100 d-flex align-items-baseline mb-3">
                                        <div className="profile-label w-35">
                                            <p className="f14-size fw-500 text-gray">Email </p>
                                        </div>
                                        <div className="w-10">:</div>
                                        <div className="profile-input w-55">
                                            <p className="f14-size  fw-500 ">{info1?.email}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 text-black">
                                    <div className="profile-details w-100 d-flex align-items-baseline mb-3">
                                        <div className="profile-label w-35">
                                            <p className="f14-size fw-500 text-gray">Plan Exp Date</p>
                                        </div>
                                        <div className="w-10">:</div>
                                        <div className="profile-input w-55">
                                            <p className="f14-size  fw-500 ">  {info?.end_date ? moment(info?.end_date).format('Do MMM, YYYY') : ""}</p>
                                        </div>
                                    </div>
                                    <div className="profile-details w-100 d-flex align-items-baseline mb-3">
                                        <div className="profile-label w-35">
                                            <p className="f14-size fw-500 text-gray">Plan Price </p>
                                        </div>
                                        <div className="w-10">:</div>
                                        <div className="profile-input w-55">
                                            <p className="f14-size fw-500">
                                                {info?.net_amount ? `₹${info.net_amount}` : " "}
                                                <a className="f16-size text-decoration-underline red-text"></a>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="profile-details w-100 d-flex align-items-baseline mb-3">
                                        <div className="profile-label w-35">
                                            <p className="f14-size fw-500 text-gray">Branch </p>
                                        </div>
                                        <div className="w-10">:</div>
                                        <div className="profile-input w-55">
                                            <p className="f14-size fw-500">
                                                {info?.branch_address}
                                                <a className="f16-size text-decoration-underline red-text"></a>
                                            </p>
                                        </div>

                                    </div>
                                    {/* <div className="profile-details w-100 d-flex align-items-baseline mb-3">
                                        <div className="profile-label w-35">
                                            <p className="f14-size fw-500 text-gray">Sale Rep </p>
                                        </div>
                                        <div className="w-10">:</div>
                                        <div className="profile-input w-55">
                                            <p className="f14-size  fw-500 ">{info1?.sales_person}</p>
                                        </div>
                                    </div> */}
                                </div>

                                <div className="col-lg-3 mt-3 mt-sm-0">
                                    {membershipButton && <button onClick={() => handleOpen(null)} type="button" className={"common-btnn mt-2 "}>
                                        Create New Membership
                                    </button>
                                    }
                                    {editButton && <button onClick={handleDeleteOpen} type="button" className="common-btnn mt-2" >
                                        Assign Workout
                                    </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-0 mt-lg-4">
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="nav flex-column nav-tabs text-center tabs-customer" id="v-tabs-tab"
                                        role="tablist" aria-orientation="vertical">
                                        <a className="nav-link active" id="v-tabs-About-tab" data-bs-toggle="tab"
                                            href="#v-tabs-About" role="tab" aria-controls="v-tabs-About"
                                            aria-selected="true" onClick={() => handleTabClick('About')}>About</a>
                                        <a className="nav-link" id="v-tabs-Membership-tab" data-bs-toggle="tab"
                                            href="#v-tabs-Membership" role="tab" aria-controls="v-tabs-Membership"
                                            aria-selected="false" onClick={() => handleTabClick('Membership')}>Membership
                                        </a>
                                        <a className="nav-link" id="v-tabs-bills-tab" data-bs-toggle="tab"
                                            href="#v-tabs-bills" role="tab" aria-controls="v-tabs-bills"
                                            aria-selected="false" onClick={() => handleTabClick('Biils')}>Bill
                                        </a>
                                        <a className="nav-link" id="v-tabs-freeze-tab" data-bs-toggle="tab"
                                            href="#v-tabs-freeze" role="tab" aria-controls="v-tabs-freeze"
                                            aria-selected="false" onClick={() => handleTabClick('freeze')}>Freeze History
                                        </a>

                                        <a className="nav-link" id="v-tabs-Workouts-tab" data-bs-toggle="tab"
                                            href="#v-tabs-Workouts" role="tab" aria-controls="v-tabs-Workouts"
                                            aria-selected="false" onClick={() => handleTabClick('Workouts')}>Workouts Plan</a>
                                        <a className="nav-link mb-0" id="v-tabs-consent-tab" data-bs-toggle="tab" href="#v-tabs-Consent"
                                            role="tab" aria-controls="v-tabs-consent" aria-selected="false" onClick={() => handleTabClick('consent')}>Consent Form</a>
                                        <a className="nav-link mb-0" id="v-tabs-client-tab" data-bs-toggle="tab" href="#v-tabs-client"
                                            role="tab" aria-controls="v-tabs-client" aria-selected="false" onClick={() => handleTabClick('client')}>References client</a>
                                    </div>
                                </div>
                                <div className="col-lg-9">
                                    <div className="tab-content top-tab-consent" id="v-tabs-tabContent">
                                        <AboutEdit info={info} postApi={postApi} handleChange={handleChange} errors={errors} setDateOfBirth={setDateOfBirth} setErrors={setErrors} customerList={customerList} referSymbol={referSymbol} branch={branch} />
                                        <div className="tab-pane fade" id="v-tabs-Membership" role="tabpanel"
                                            aria-labelledby="v-tabs-Membership-tab">
                                            <Membership handleMembershipOpen={handleMembershipOpen} handleOpen={handleOpen} total={total} membershipList={membershipList} getApi={getApi} membership={membership} currentPage={currentPage} handlePageChange={handlePageChange} startIndex={startIndex} endIndex={endIndex} totalPages={totalPages} handleBalanceOpen={handleBalanceOpen} />
                                        </div>
                                        <div className="tab-pane fade" id="v-tabs-bills" role="tabpanel"
                                            aria-labelledby="v-tabs-bills-tab">
                                            <Bills handleBillOpen={handleBillOpen} total1={total1} bill={bill} getApi={getApi} currentPage={currentPage} handlePageChange={handlePageChange} startIndex={startIndex} endIndex1={endIndex1} totalPages1={totalPages1} setLoading={setLoading} gymDetail={gymDetail} email={info1.email} customerId={location.state.id} />
                                        </div>
                                        <div className="tab-pane fade" id="v-tabs-freeze" role="tabpanel"
                                            aria-labelledby="v-tabs-freeze-tab">
                                            <Freeze handleBillOpen={handleBillOpen} total1={total1} bill={freeze} getApi={getApi} currentPage={currentPage} handlePageChange={handlePageChange} startIndex={startIndex} endIndex1={endIndex2} totalPages1={totalPages2} getBillApi={getBillApi} />
                                        </div>
                                        <div className="tab-pane fade" id="v-tabs-Workouts" role="tabpanel"
                                            aria-labelledby="v-tabs-Workouts-tab">
                                            <Workout activeDay={activeDay} workoutDetail={workoutDetail} excerciseList={excerciseList} handleDayClick={handleDayClick} />                                        </div>
                                        <div className="tab-pane fade" id="v-tabs-Consent" role="tabpanel"
                                            aria-labelledby="v-tabs-consent-tab">
                                            <Consent selectedFile={selectedFile} previewUrl={previewUrl} fileChange={fileChange} handleDelete={handleDelete} />
                                        </div>
                                        <div className="tab-pane fade" id="v-tabs-client" role="tabpanel"
                                            aria-labelledby="v-tabs-client-tab">
                                            <Client client={client} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal-head purchase-modal">
                    <div
                        onClick={handleClose}
                        className=" position-relative mb-0 text-end pt-1 pe-1"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={CROSS_BTN} />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h2 className='modalHeading'>{membershipId != null ? "Edit Membership " : "Purchase Membership"}</h2>
                    </div>

                    <form className='create-field purchase-create-field'>
                        <div className='row'>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3">
                                    <label className="form-label mb-0">Training type </label>
                                    <select name="plan_type" className="form-select common-input" value={data1?.plan_type} onChange={handleChange2} disabled={membershipId != null}
                                    >
                                        <option value="1">General training</option>
                                        <option value="2">Personal training</option>
                                    </select>
                                </div>
                            </div>

                            {
                                data1?.plan_type == 2 && <div className="col-md-12">
                                    <div className="mb-2 mb-xxl-3">
                                        <label className="form-label mb-0">Trainer </label>

                                        <select name='trainer_id' value={data1.trainer_id} onChange={handleChange2} className="form-select common-input" aria-label="Default select example" disabled={membershipId != null}>
                                            <option value="">Select Trainer</option>
                                            {
                                                trainerList?.map((item) => {
                                                    return (
                                                        <>
                                                            <option key={item.id} value={item.id}>{item.name}</option>
                                                        </>
                                                    )
                                                })
                                            }
                                            {errors.trainer_id && <span className="err_msg">{errors.trainer_id}</span>}
                                        </select>
                                    </div>
                                </div>
                            }
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3">
                                    <label className="form-label mb-0">Base Amount</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className='dollar-Sign'>₹</span>
                                        </div>
                                        <input
                                            disabled={membershipId != null}
                                            type="number"
                                            name="base_amount"
                                            value={data1?.base_amount?.slice(0, 15)}
                                            onChange={handleChange2}
                                            className="form-control common-input dollarInput"
                                            placeholder="Enter Price"
                                        />
                                    </div>
                                    {errors.base_amount && <span className="err_msg">{errors.base_amount}</span>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3">
                                    <label className="form-label mb-0">DISCOUNT type </label>
                                    <select name="discount_type" className="form-select common-input" value={data1?.discount_type} disabled={membershipId != null}
                                        onChange={handleChange2}>
                                        <option value="">Select</option>
                                        <option value="1">%(Percentage)</option>
                                        <option value="2">Lump sum</option>
                                    </select>
                                    {errors.discount_type && <span className="err_msg">{errors.discount_type}</span>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">DISCOUNT</label><input type="number" className="form-control common-input" placeholder="Enter Discount" value={data1?.discount} onChange={handleChange2} name="discount" disabled={!data1?.discount_type || membershipId != null} />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Net amount</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className='dollar-Sign'>₹</span>
                                        </div>
                                        <input
                                            disabled={membershipId != null}
                                            type="number"
                                            name="net_amount"
                                            value={data1?.net_amount}
                                            onChange={handleChange2}
                                            readOnly={data1?.discount_type === "1" || !data1?.discount_type || data1.discount_type === "2"}
                                            className="form-control common-input dollarInput"
                                            placeholder="Enter Price"
                                        />
                                    </div>
                                    {errors.net_amount && <span className="err_msg">{errors.net_amount}</span>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">PAID amount</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className='dollar-Sign'>₹</span>
                                        </div>
                                        <input
                                            type="number" disabled={membershipId != null} placeholder="Enter paid amount" value={data1?.paid_amount} onChange={handleChange2} name="paid_amount"
                                            className="form-control common-input dollarInput"
                                        />
                                    </div>
                                    {errors.paid_amount && <span className="err_msg">{errors.paid_amount}</span>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Balance amount</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className='dollar-Sign'>₹</span>
                                        </div>
                                        <input
                                            disabled={membershipId != null}
                                            type="number" placeholder="Enter balance amount" value={data1?.balance_amount}
                                            name="balance_amount"
                                            className="form-control common-input dollarInput"
                                            onChange={(e) => {

                                                if (membershipId !== null) {
                                                    handleChange2(e);
                                                }
                                            }}
                                        />
                                    </div>

                                </div>
                            </div>
                            {/* {
                                membershipId != null && <div className="col-md-12">
                                    <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Paid Balance amount</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className='dollar-Sign'>₹</span>
                                            </div>
                                            <input
                                                type="number" placeholder="Enter balance amount" value={data1?.paid_balance_amout}
                                                onChange={handleChange2}
                                                name="paid_balance_amout"
                                                className="form-control common-input dollarInput"

                                            />
                                        </div>
                                    </div>
                                </div>
                            } */}

                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">MONTH</label><input type="number" className="form-control common-input" placeholder="Enter Month" value={data1?.month} onChange={handleChange2} name="month" disabled={membershipId != null} />
                                    {errors.month && <span className="err_msg">{errors.month}</span>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Days</label><input type="number" className="form-control common-input" placeholder="Enter days" value={data1?.days}
                                    onChange={handleChange2} name="days" disabled={membershipId != null} />

                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">START date</label>
                                    <DatePickerValue
                                        setErrors={setErrors}
                                        value={data1?.start_date ? data1.start_date : currentDate}
                                        setFn={handleStart}
                                    />
                                    {errors.start_date && <span className="err_msg">{errors.start_date}</span>}
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">End Date</label>
                                    <DatePickerValue
                                        setErrors={setErrors}
                                        value={data1?.end_date}
                                        setFn={setMemberSince}
                                        endDate={data1?.end_date}
                                        name="planEnd"
                                    />

                                    {errors.end_date && <span className="err_msg">{errors.end_date}</span>}
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Billing date</label>
                                    <DatePickerValue
                                        setErrors={setErrors}
                                        value={data1?.bill_date}
                                        setFn={setBillSince}
                                        name={membershipId != null && "planEnd"}
                                    />
                                    {errors.bill_date && <span className="err_msg">{errors.bill_date}</span>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3">
                                    <label className="form-label mb-0">PAYMENT MODE</label>
                                    <select name="mode_of_payment" className="form-select common-input" value={data1?.mode_of_payment} onChange={handleChange2} disabled={membershipId != null} >
                                        <option selected>Select</option>
                                        <option value="cash">Cash</option>
                                        <option value="gpay">Gpay</option>
                                        <option value="swipe_machine">Swipe Machine</option>
                                    </select>
                                    {errors.mode_of_payment && <span className="err_msg">{errors.mode_of_payment}</span>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">TRANSACTION ID</label><input type="text" value={data1?.transaction_id} disabled={membershipId != null} onChange={handleChange2} name="transaction_id" className="form-control common-input" placeholder="Enter ID" />
                                    {errors.transaction_id && <span className="err_msg">{errors.transaction_id}</span>}
                                </div>

                            </div>
                        </div>


                    </form>
                    {
                        membershipId != null ? <div className="mt-4 mb-3 text-center position-relative submit-head">
                            <button onClick={(e) => EditMembershipApi(e, data1.id)}
                                type="submit"
                                className="common-btnn w-100"
                            >
                                Save
                            </button>
                        </div> : <div className="mt-4 mb-3 text-center position-relative submit-head">
                            <button onClick={postMembershipApi}
                                type="submit"
                                className="common-btnn w-100"
                            >
                                Submit
                            </button>
                        </div>
                    }
                </Box>

            </Modal>
            <Modal
                open={openBalance}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal-head purchase-modal">
                    <div
                        onClick={handleBalanceClose}
                        className=" position-relative mb-0 text-end pt-1 pe-1"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={CROSS_BTN} alt="" />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h2 className='modalHeading'>Pay Balance Amount</h2>
                    </div>

                    <form className='create-field purchase-create-field'>
                        <div className='row'>


                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">Balance amount</label>
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span className='dollar-Sign'>₹</span>
                                        </div>
                                        <input
                                            type="number" placeholder="Enter paid amount" value={dataBalance?.balance_amount} onChange={handleChangeBalance} name="balance_amount"
                                            className="form-control common-input dollarInput"
                                        />
                                    </div>
                                    {errors.balance_amount && <span className="err_msg">{errors.balance_amount}</span>}
                                </div>
                            </div>


                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3">
                                    <label className="form-label mb-0">PAYMENT MODE</label>
                                    <select name="mode_of_payment" className="form-select common-input" value={dataBalance?.mode_of_payment} onChange={handleChangeBalance} >
                                        <option selected>Select</option>
                                        <option value="cash">Cash</option>
                                        <option value="gpay">Gpay</option>
                                        <option value="Swipe Machine">Swipe Machine</option>
                                    </select>
                                    {errors.mode_of_payment && <span className="err_msg">{errors.mode_of_payment}</span>}
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="mb-2 mb-xxl-3"><label className="form-label mb-0">TRANSACTION ID</label><input type="text" value={dataBalance?.transaction_id} onChange={handleChangeBalance} name="transaction_id" className="form-control common-input" placeholder="Enter ID" />
                                    {errors.transaction_id && <span className="err_msg">{errors.transaction_id}</span>}
                                </div>

                            </div>
                        </div>

                    </form>
                    <div className="mt-4 mb-3 text-center position-relative submit-head">
                        <button onClick={postBlanceApi}
                            type="submit"
                            className="common-btnn w-100"
                        >
                            Submit
                        </button>
                    </div>

                </Box>

            </Modal>
            <Modal
                open={openDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal-head">
                    <div
                        onClick={handleDeleteClose}
                        className=" position-relative mb-0 text-end pt-1"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={CROSS_BTN} alt="" />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h2>Assign Workout</h2>
                    </div>
                    <div className='assign-modal-body px-3'>
                        <div className="col-12">
                            <div className="mb-4">
                                <input
                                    type="text"
                                    name="sets"
                                    className={`form-control common-input ${inputValue !== '' ? "input-drop" : ""}`}
                                    placeholder="Enter here"
                                    value={inputValue}
                                    onChange={handleInputChange}
                                />
                                {noResults && inputValue !== '' && (
                                    <div className="text-center mt-2">
                                        <span>No results found</span>
                                    </div>
                                )}
                                {
                                    inputValue &&
                                    <div className='dropBox'>
                                        <ul className='drop-list'>
                                            {
                                                filteredExercises.map((item) => (
                                                    <li key={item.id} className='d-flex align-items-center justify-content-between'>
                                                        <span>{item.workout_name}</span>
                                                        <input
                                                            type="checkbox"
                                                            onChange={() => handleCheckboxChange(item)}
                                                            checked={selectedValue && selectedValue.id === item.id}
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                }

                                {error && (
                                    <span className="err_msg">{error}</span>
                                )}
                                <div className='my-3 d-flex align-items-center gap-3 flex-wrap  drop-max-height'>

                                    {selectedValue && (
                                        <div className='drop_wrapper'>
                                            <span>{selectedValue.workout_name}</span>
                                            <img src={CROSSED} style={{ cursor: "pointer" }}
                                                alt=""
                                                className="img-fluid"
                                                onClick={handleCrossClick} />
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="assign-modal-body px-3">
                        <div className="mb-3">
                            <label className="form-label">start date</label>
                            <input type="date" name='date_of_birth' value={startDate} onChange={(e) => {
                                setError("")
                                setError1("")
                                setStartDate(e.target.value)
                            }} className="form-control common-input" />
                            {error1 && <span className="err_msg">{error1}</span>}
                        </div>
                    </div>
                    <div
                        className="mb-3 text-center position-relative submit-head"
                        onClick={postAssignApi}
                    >
                        <button
                            type="submit"
                            className="btn delete-btn"
                        >
                            Assign Workout{" "}
                        </button>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openMembership}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleMembership} className="modal-head">
                    <div
                        onClick={handleMembershipClose}
                        className=" position-relative mb-0 text-end pt-1"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={CROSS_BTN} alt="" />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h2>Delete Membership</h2>
                    </div>
                    <div className="mb-4  position-relative">
                        <p>
                            Are you sure you want to delete the membership?

                        </p>
                    </div>
                    <div
                        className="mb-3 text-center position-relative submit-head"
                        onClick={getMembershipDeleteAPI}
                    >
                        <button type="submit" className="btn delete-btn">
                            Delete
                        </button>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openBill}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleMembership} className="modal-head">
                    <div
                        onClick={handleBillClose}
                        className=" position-relative mb-0 text-end pt-1"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={CROSS_BTN} alt="" />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h2>Delete Bill</h2>
                    </div>
                    <div className="mb-4  position-relative">
                        <p>
                            Are you sure you want to delete the bill?

                        </p>
                    </div>
                    <div
                        className="mb-3 text-center position-relative submit-head"
                        onClick={getBillDeleteAPI}
                    >
                        <button type="submit" className="btn delete-btn">
                            Delete
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default EditCustomer;