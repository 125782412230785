import React, { useEffect, useState } from 'react'
import "../../../assets/style/addLeads.css"
import Services from '../../../Helper/services'
import ReactLoader from '../../../Loader/react-loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
import ERR_MESSAGE from '../../../Helper/error-helper';
import ROUTES from '../../../Middleware/routes';
import { useNavigate } from 'react-router-dom';
import UpdateConversation from './updateConversation';
import TITLES from '../../../Helper/title';
import { Helmet } from 'react-helmet';
import DatePickerValue from '../../../components/date-picker';

const AddLeads = () => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [prospectTypes, setProspectTypes] = useState([])
  const [locations, setLocations] = useState([])
  const [callSubjects, setCallSubjects] = useState([])
  const [callResponses, setCallResponses] = useState([])
  const [reasons, setreasons] = useState([])
  const [priorites, setPriorities] = useState([])
  const [errors, setErrors] = useState("");
  const [open, setOpen] = useState(false)
  const [staffs, setStaffs] = useState([])
  const currentDate = new Date().toISOString().split('T')[0];
  const [branch, setBranch] = useState([]);

  const [fields, setFields] = useState({
    mobile_no: "",
    name: "",
    whatsapp_no: "",
    prospectDate: currentDate,
    source: "Walking",
    gender: "",
    location: "",
    conversation: "0",
    callSubject: "",
    callResponse: "",
    reason: "",
    priority: "",
    nextCallDate: "",
    nextCallTime: "",
    callDate: "",
    callStatus: "",
    staff: "",
    remark: "",
    branch:localStorage.getItem("branch_id") == 1 && localStorage.getItem("role_id") == 1 ?  localStorage.getItem("branch_id") :""
  })
  const handleChange = (e) => {
    setErrors("")
    const name = e.target.name
    const value = e.target.value
    const regex = /^[0-9]*$/;
    if (name === 'mobile_no' && !regex.test(value)) {
      return;
    }
    if (name === 'whatsapp_no' && !regex.test(value)) {
      return;
    }
    if (name === 'mobile_no') {
      setFields({
        ...fields,
        [name]: value,
        whatsapp_no: value
      });
    } else {
      setFields({
        ...fields,
        [name]: value
      });
    }

    if (name === "conversation") {
      if (e.target.value === "1") {
        setOpen(true)
      }
      else {
        setOpen(false)
      }
    }
  }

  const setProspectDate = (newDate) => {
    setFields((prevFields) => ({
      ...prevFields,
      prospectDate: newDate,
    }));
  };

  const setCallate = (newDate) => {
    setFields((prevFields) => ({
      ...prevFields,
      callDate: newDate,
    }));
  };

  const setNextCallDate = (newDate) => {
    setFields((prevFields) => ({
      ...prevFields,
      nextCallDate: newDate,
    }));
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    const whatsappRegex = /^\d+$/;
    const emojiSymbolRegex = /[^\p{L}\s]/u;
    const { mobile_no, whatsapp_no, name, prospectDate, source, conversation,
      callResponse, priority, nextCallDate, nextCallTime, callDate, remark ,branch} = fields
    if (!name || name?.trim().length === 0) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.EMPTY_FULLNAME;
    } else if (name.length < 3) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.INVALID_FULLNAME_MIN;
    }
    else if (name.length > 30) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.INVALID_FULLNAME_MAX;
    }
    else {
      const regex = /^[a-zA-Z\s]*$/;

      if (!regex.test(name) || emojiSymbolRegex.test(name)) {
        formIsValid = false;
        errors["name"] = ERR_MESSAGE.INVALID_FULLNAME;
      }
    }
    if (!mobile_no || mobile_no?.trim().length === 0) {
      formIsValid = false;
      errors["mobile_no"] = ERR_MESSAGE.MOBILE_EMPTY
    }
    else if (!whatsappRegex.test(mobile_no)) {
      formIsValid = false;
      errors["mobile_no"] = ERR_MESSAGE.INVALID_MOBILE
    }
    else if (mobile_no.length < 10) {
      formIsValid = false;
      errors["mobile_no"] = ERR_MESSAGE.INVALID_MOBILE_MIN
    }
    if (!whatsapp_no || whatsapp_no?.trim().length === 0) {
      formIsValid = false;
      errors["whatsapp_no"] = ERR_MESSAGE.WHATSAPP_EMPTY
    }
    else if (!whatsappRegex.test(whatsapp_no)) {
      formIsValid = false;
      errors["whatsapp_no"] = ERR_MESSAGE.INVALID_MOBILE
    }
    else if (whatsapp_no.length < 10) {
      formIsValid = false;
      errors["whatsapp_no"] = ERR_MESSAGE.INVALID_MOBILE_MIN
    }

    if (!prospectDate || prospectDate.length === 0) {
      formIsValid = false;
      errors["prospectDate"] = ERR_MESSAGE.EMPTY_DATE;
    } else {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);

      const selectedDate = new Date(prospectDate);
      selectedDate.setHours(0, 0, 0, 0);

      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(currentDate.getDate() - 7);
      sevenDaysAgo.setHours(0, 0, 0, 0);

      if (selectedDate < sevenDaysAgo || selectedDate > currentDate) {
        formIsValid = false;
        errors["prospectDate"] = ERR_MESSAGE.INVALID_DATE;
      }
    }

    if (!source || source.length === 0) {
      formIsValid = false;
      errors["source"] = ERR_MESSAGE.EMPTY_SOURCE;
    }
    if (!conversation) {
      formIsValid = false;
      errors["conversation"] = ERR_MESSAGE.EMPTY_CONVERSATION;
    }
    if (!branch || branch.length === 0) {
      formIsValid = false;
      errors["branch"] = ERR_MESSAGE.EMPTY_BRANCH;
  }
    if (open === true) {
      if (!callDate || callDate.length === 0) {
        formIsValid = false;
        errors["callDate"] = ERR_MESSAGE.EMPTY_DATE;
      }
      else {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const selectedDate = new Date(callDate);
        selectedDate.setHours(0, 0, 0, 0);

        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(currentDate.getDate() - 7);
        sevenDaysAgo.setHours(0, 0, 0, 0);

        if (selectedDate < sevenDaysAgo || selectedDate > currentDate) {
          formIsValid = false;
          errors["callDate"] = ERR_MESSAGE.INVALID_DATE;
        }
      }
      if (!callResponse || callResponse.length === 0) {
        formIsValid = false;
        errors["callResponse"] = ERR_MESSAGE.EMPTY_CALLRESPONSE;
      }

      if (fields.callResponse === "Ask for call again" || fields.callResponse == 'Not answered') {
        if (!priority || priority.length === 0) {
          formIsValid = false;
          errors["priority"] = ERR_MESSAGE.EMPTY_PRIORITY;
        }
        if (!nextCallDate || nextCallDate.length === 0) {
          formIsValid = false;
          errors["nextCallDate"] = ERR_MESSAGE.EMPTY_DATE;
        }
        else {
          const currentDate = new Date();
          currentDate.setHours(0, 0, 0, 0);

          const selectedDate = new Date(nextCallDate);
          selectedDate.setHours(0, 0, 0, 0);

          if (selectedDate < currentDate) {
            formIsValid = false;
            errors["nextCallDate"] = ERR_MESSAGE.INVALID_DATE;
          }
        }
        if (!nextCallTime || nextCallTime.length === 0) {
          formIsValid = false;
          errors["nextCallTime"] = ERR_MESSAGE.EMPTY_TIME;
        }
        else if (remark.length > 300 && remark.length > 0) {
          formIsValid = false;
          errors["remark"] = ERR_MESSAGE.INVALID_REMARK;
        }
      }

      if (fields.callResponse === "Interested" || fields.callResponse == 'Not answered') {
        if (!remark || remark.length === 0) {
          formIsValid = false;
          errors["remark"] = ERR_MESSAGE.EMPTY_REMARK;
        }
        else if (remark.length > 300) {
          formIsValid = false;
          errors["remark"] = ERR_MESSAGE.INVALID_REMARK;
        }
      }

    }

    setErrors(errors);
    return formIsValid;
  }
  async function handleSubmit(e) {
    e.preventDefault();
    if (handleValidation()) {
      const commonFields = {
        "company_id": localStorage.getItem("company_id"),
        // "branch_id": localStorage.getItem("branch_id"),
        "mobile_no": fields?.mobile_no,
        "whatsapp_no": fields.whatsapp_no,
        "name": fields?.name,
        "prospect_date": fields?.prospectDate,
        "source": fields?.source,
        "gender": fields?.gender,
        "location": fields?.location,
        "conversation": fields?.conversation,
        "branch_id": fields.branch
      };
      let data = JSON.stringify(open === false
        ? commonFields
        : {
          ...commonFields,
          "leads_conversation": {
            "call_response": fields?.callResponse,
            "priority": fields?.priority,
            "next_call_date": fields?.nextCallDate,
            "next_call_time": fields?.nextCallTime,
            "call_date": fields?.callDate,
            "remark": fields?.remark,
            "staff_assign_id": fields?.staff,
            "branch_id": fields.branch

          }
        });

      setLoading(true);
      const result = await Services.postService("lead/add-leads", data);

      if (result.code === DEVELOPMENT_CONFIG.statusCode) {
        setLoading(false);
        localStorage.setItem("message", result.message)
        navigate(ROUTES.LEADES);
        setFields({
          ...fields,
          mobile_no: "",
          name: "",
          source: "",
          gender: "",
          prospectDate: "",
          location: "",
          conversation: "",
          callSubject: "",
          callResponse: "",
          reason: "",
          priority: "",
          nextCallDate: "",
          nextCallTime: "",
          callDate: "",
          callStatus: "",
          staff: "",
          remark: "",
        });

      } else {
        setLoading(false);
        toast.error(result.message);
      }
    }
  }
  let role_id = localStorage.getItem("role_id")
  async function leadData() {
    let result = await Services.getService("lead/leads-sources");
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setLocations(result?.body?.leadData)
      setCallSubjects(result?.body?.subject)
      setCallResponses(result?.body?.response)
      setreasons(result?.body?.reason)
      setPriorities(result?.body?.priority)
      setProspectTypes(result?.body?.sources)
    }
    else {
    }
  }

  async function staffApi() {
    const branch_id = localStorage.getItem("branch_id")
    let result = await Services.getService(`lead/staff-list?&branch_id=${branch_id}`);
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setStaffs(result?.body)
    }
    else {
    }
  }


  const handleChange1 = (event) => {
    setErrors("")
    const { name, value } = event.target;
    if (value.length > 600) {
      setErrors({ ...errors, remark: ERR_MESSAGE.INVALID_REMARK });
      setFields({ ...fields, [name]: value });
    } else {
      setFields({ ...fields, [name]: value });
      setErrors("")
    };
  }

  useEffect(() => {
    leadData()
    staffApi()
    getBranchApi()

  }, [])

  async function getBranchApi() {
    const company_id = localStorage.getItem("company_id")
    let result = await Services.getService(`admin/role-branch-list?id=${company_id}`);
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setBranch(result?.body?.branch)
    }
    else {
    }
  }

  return (
    <div>
      <Helmet>
        <title>{TITLES.ADD_LEADS}</title>
      </Helmet>
      <ToastContainer />
      {loading && <ReactLoader />}
      <section className="add-customer common-bg form-height">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="add-customer-head">
                <h2 className="add-customer-text fw-600">New Lead</h2>
              </div>
            </div>
            <div className="col-12">
              <form >
                <div className="row">
                  <div className="col-12">
                    <div className="basic-info my-4">
                      <h4 className="basic-head fw-600">Basic Info</h4>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <div className="mb-3">
                      <label className="form-label">Name</label>
                      <input type="text" name='name' value={fields?.name} onChange={handleChange} className="form-control common-input" placeholder="Enter Name" />
                      {errors.name && <span className="err_msg">{errors.name}</span>}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <div className="mb-3">
                      <label className="form-label">Mobile No</label>
                      <input type="text" name='mobile_no' value={fields?.mobile_no.slice(0, 10)} onChange={handleChange} className="form-control common-input" placeholder="Enter Mobile No" />
                      {errors.mobile_no && <span className="err_msg">{errors.mobile_no}</span>}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <div className="mb-3">
                      <label className="form-label">Whatsapp No</label>
                      <input type="text" name='whatsapp_no' value={fields?.whatsapp_no.slice(0, 10)} onChange={handleChange} className="form-control common-input" placeholder="Enter Mobile No" />
                      {errors.whatsapp_no && <span className="err_msg">{errors.whatsapp_no}</span>}
                    </div>
                  </div>
                 
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <div className="mb-3">
                      <label className="form-label">Prospect Date</label>
                      <DatePickerValue
                        setErrors={setErrors}
                        value={fields?.prospectDate}
                        setFn={setProspectDate}
                      />
                      {errors.prospectDate && <span className="err_msg">{errors.prospectDate}</span>}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <div className="mb-3">
                      <label className="form-label">Source</label>
                      <select name='source' value={fields?.source} onChange={handleChange} className="form-select common-input">
                        {
                          prospectTypes?.map((item) => {
                            return (
                              <>
                                <option key={item.id} value={item.name}>{item.name}</option>
                              </>
                            )
                          })
                        }
                      </select>
                      {errors.source && <span className="err_msg">{errors.source}</span>}
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <div className="mb-3">
                      <label className="form-label">Gender</label>
                      <select name='gender' value={fields?.gender} onChange={handleChange} className="form-select common-input">
                        <option value="" selected>Select</option>
                        <option value="2">Female</option>
                        <option value="1">Male</option>
                        <option value="3">Others</option>
                      </select>
                      {errors.gender && <span className="err_msg">{errors.gender}</span>}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <div className="mb-3">
                      <label className="form-label">Location</label>
                     
                      <input type="text" name='location' value={fields?.location} onChange={handleChange} className="form-control common-input" placeholder="Enter location" />
                      {errors.location && <span className="err_msg">{errors.location}</span>}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <div className="mb-3">
                      <label className="form-label">Branch</label>
                      {
                        localStorage.getItem("role_id") == 1
                          ?
                          <select type="text" name='branch' value={fields.branch} onChange={handleChange} className="form-select common-input" disabled aria-label="Enter Role">
                            {/* <option value="">Select</option> */}
                            {
                              branch.filter(item => item.id == localStorage.getItem("branch_id"))?.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>{item?.address_line_2},{item.city}</option>

                                )
                              })
                            }
                          </select> :
                          <select type="text" name='branch' value={fields.branch} onChange={handleChange} className="form-select common-input" aria-label="Enter Role">
                            <option value="">Select</option>
                            {
                              branch?.map((item) => {
                                return (
                                  <option key={item.id} value={item.id}>{item?.address_line_2},{item.city}</option>

                                )
                              })
                            }
                          </select>
                      }

                      {errors.branch && <span className="err_msg">{errors.branch}</span>}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                    <div className="mb-3">
                      <label className="form-label">Conversation</label>
                      <select name='conversation' value={fields?.conversation} onChange={handleChange} className="form-select common-input">
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select>
                      {errors.conversation && <span className="err_msg">{errors.conversation}</span>}
                    </div>
                  </div>

                  {
                    open && <UpdateConversation handleChange1={handleChange1} role_id={role_id} setNextCallDate={setNextCallDate} setCallate={setCallate} fields={fields} handleChange={handleChange} errors={errors}
                      callSubjects={callSubjects} reasons={reasons} callResponses={callResponses} priorites={priorites} setErrors={setErrors} handleSubmit={handleSubmit} staffs={staffs} />
                  }
                  <div className="col-12  d-flex justify-content-end align-self-end">
                    <button type="submit" className=" next-btn" onClick={handleSubmit}>Submit</button>
                  </div>


                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AddLeads;
