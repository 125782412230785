import React, { useEffect, useState } from 'react'
import { CROSSED, USER_PROFILE } from '../../../Middleware/images';
import "../../../assets/style/addCustomers.css";
import {  useLocation, useNavigate } from 'react-router-dom';
import ReactLoader from '../../../Loader/react-loader';
import uploadFileToS3 from "../../../Helper/fileUpload"
import { Helmet } from 'react-helmet';
import TITLES from '../../../Helper/title';
import Services from '../../../Helper/services';
import DEVELOPMENT_CONFIG from '../../../Helper/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ERR_MESSAGE from '../../../Helper/error-helper';
import ROUTES from '../../../Middleware/routes';

const EditStaff = () => {
    const location = useLocation()
    const id = location?.state?.id
    const [errors, setErrors] = useState("");
    const [branch_id, setBranch] = useState([]);
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState([]);
    const [specialization1_name, setSpecializationName] = useState('');
    const navigate = useNavigate()

    const [info, setInfo] = useState({
        profile_pic: "",
        name: "",
        blood_group: "",
        emergency_no: "",
        whatsapp_no: "",
        mobile_number: "",
        experience: "",
        email: "",
        role_id: "",
        branch_id: "",
        gender: "",
        address_1: "",
        address_2: "",
        address_3: "",
        state: "",
        city: "",
        country: "",
        pincode: "",
        about: "",
        specialization: [],
        shift: '',
    });

    function handleChange2(event) {
        setErrors("")
        setSpecializationName(event.target.value);
    }

    const fileChange1 = (e) => {
        setErrors("");
        if (e.target.files.length === 0) {
            return;
        }
        const file = e.target.files[0];
        if (file) {
            if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
                const formData = new FormData();
                formData.append("image", file);
                const imageURL = URL.createObjectURL(file);
                setInfo({
                    ...info,
                    profile_pic: imageURL
                })

                fileUpload(file)
            }
        }
    };

    const fileUpload = async (file) => {
        try {
            if (file) {
                const fileUrl = await uploadFileToS3(file);
                setInfo({
                    ...info,
                    profile_pic: fileUrl
                })
            }
        }
        catch (error) {
        }
    };

    const handleChange = (e) => {
        setErrors("")
        const name = e.target.name
        const value = e.target.value
        const regex = /^[0-9]*$/;
        if (name === 'whatsapp_no' && !regex.test(value)) {
            return;
        }
        if (name === 'alternate_no' && !regex.test(value)) {
            return;
        }
        if (name === 'emergency_no' && !regex.test(value)) {
            return;
        }
        setInfo({
            ...info, [name]: value
        })
    }

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        const emojiSymbolRegex = /[^\p{L}\s]/u;
        const whatsappRegex = /^\d+$/;
        const { name, shift, role_id, whatsapp_no, emergency_no, mobile_number, email, about, specialization, branch_id, state, gender, city, address_1, pincode,password  } = info

        if (!name || name?.trim().length === 0) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.EMPTY_FULLNAME;
        }
        else if (name?.length < 3 && name?.length > 0) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_FULLNAME_MIN;
        }
        else if (name.length > 30) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_FULLNAME_MAX;
        }
        else if (emojiSymbolRegex.test(name)) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_FULLNAME;
        }

        if (!mobile_number || mobile_number?.trim().length === 0) {
            formIsValid = false;
            errors["mobile_number"] = ERR_MESSAGE.MOBILE_EMPTY
        }
        else if (!whatsappRegex.test(mobile_number) && mobile_number?.length > 1) {
            formIsValid = false;
            errors["mobile_number"] = ERR_MESSAGE.INVALID_MOBILE
        }
        else if (mobile_number.length < 10 && mobile_number?.length > 1) {
            formIsValid = false;
            errors["mobile_number"] = ERR_MESSAGE.INVALID_MOBILE_MIN
        }

        if (!whatsapp_no || whatsapp_no?.trim().length === 0) {
            formIsValid = false;
            errors["whatsapp_no"] = ERR_MESSAGE.MOBILE_EMPTY

        }
        else if (!whatsappRegex.test(whatsapp_no) && whatsapp_no?.length > 1) {
            formIsValid = false;
            errors["whatsapp_no"] = ERR_MESSAGE.INVALID_MOBILE
        }
        else if (whatsapp_no.length < 10 && whatsapp_no?.length > 1) {
            formIsValid = false;
            errors["whatsapp_no"] = ERR_MESSAGE.INVALID_MOBILE_MIN
        }

        if (!whatsappRegex.test(emergency_no) && emergency_no?.length > 1) {
            formIsValid = false;
            errors["emergency_no"] = ERR_MESSAGE.INVALID_MOBILE
        }
        else if (emergency_no.length < 10 && emergency_no?.length > 1) {
            formIsValid = false;
            errors["emergency_no"] = ERR_MESSAGE.INVALID_MOBILE_MIN
        }

        if (!email || email?.trim().length === 0) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_EMPTY;
        }
        else if (reg.test(email.toLowerCase()) === false && email?.length > 1) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_INVALID;
        }
        if (!role_id || role_id.length === 0) {
            formIsValid = false;
            errors["role_id"] = ERR_MESSAGE.EMPTY_ROLE;
        }
        // if (role_id == '1') {
        //     if (!password) {
        //         formIsValid = false;
        //         errors["password"] = ERR_MESSAGE.PASSWORD_EMPTY;
        //     }
        //     else if (passwordReg.test(password) === false) {
        //         formIsValid = false;
        //         errors["password"] = ERR_MESSAGE.INVALID_PASSWORD
        //     }
        // }
        if (info.role_id == "2") {
            if (!about || about.length === 0) {
                formIsValid = false;
                errors["about"] = ERR_MESSAGE.EMPTY_ABOUT;
            }
            else if (about?.length > 600 && about?.length > 1) {
                formIsValid = false;
                errors["about"] = ERR_MESSAGE.INVALID_ABOUT;
            }
            if (specialization.length == 0) {
                formIsValid = false;
                errors["specialization"] = ERR_MESSAGE.EMPTY_SPECIALIZATION;
            }
        }
        if (!branch_id || branch_id.length === 0) {
            formIsValid = false;
            errors["branch_id"] = ERR_MESSAGE.EMPTY_BRANCH;
        }
        if (!gender || gender.length === 0) {
            formIsValid = false;
            errors["gender"] = ERR_MESSAGE.EMPTY_GENDER;
        }
        if (!address_1 || address_1.length === 0) {
            formIsValid = false;
            errors["address_1"] = ERR_MESSAGE.EMPTY_ADDRESS;
        }
        if (!state || state.length === 0) {
            formIsValid = false;
            errors["state"] = ERR_MESSAGE.EMPTY_STATE;
        }
        if (!city || city.length === 0) {
            formIsValid = false;
            errors["city"] = ERR_MESSAGE.EMPTY_CITY;
        }
        if (!pincode || pincode.length === 0) {
            formIsValid = false;
            errors["pincode"] = ERR_MESSAGE.EMPTY_ZIPCODE;
        }
        if (!shift || shift.length === 0) {
            formIsValid = false;
            errors["shift"] = ERR_MESSAGE.EMPTY_STAFF_TIME;
        }

        if (about?.length > 600 && about?.length > 1) {
            formIsValid = false;
            errors["about"] = ERR_MESSAGE.INVALID_ABOUT;
        }
        setErrors(errors);
        return formIsValid;
    }

    const handleChange1 = (event) => {
        const { name, value } = event.target;
        if (value.length > 600) {
            setErrors({ ...errors, about: ERR_MESSAGE.INVALID_ABOUT });
            setInfo({ ...info, [name]: value });
        } else {
            setErrors({ ...errors, about: '' });
            setInfo({ ...info, [name]: value });

        };
    }

    async function postApi() {
        const specializationNames = info.specialization.map(item => item.name);
        let data = JSON.stringify({
            "id": id,
            "profile_pic": info.profile_pic,
            "role_id": info.role_id,
            "branch_id": info.branch_id,
            "name": info?.name,
            "emergency_no": info.emergency_no,
            "blood_group": info.blood_group,
            "mobile_number": info.mobile_number,
            "whatsapp_no": info.whatsapp_no,
            "alternate_no": info.alternate_no,
            "experience": info.experience,
            "email": info?.email,
            "gender": info?.gender,
            "address_1": info?.address_1,
            "about": info.about,
            "specialization": specializationNames,
            "address_2": info.address_2,
            "state": info.state,
            "city": info.city,
            "pincode": info.pincode,
            "country": info.country,
            "shift_hours": info.shift
        });
        setLoading(true);
        const result = await Services.postService("admin/edit-trainer", data);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            navigate(ROUTES.STAFF)
            localStorage.setItem("message", result.message)

            setLoading(false);
            getStaffApi()
        }
        else {
            toast.error(result.message)
            setLoading(false);
        }
    }

    function specializationApi() {
        setErrors('')
        if (specialization1_name) {
            const newSpecialization = { name: specialization1_name };

            setInfo(prevInfo => ({
                ...prevInfo,
                specialization: [...prevInfo?.specialization, newSpecialization]
            }));

            let existingSpecializations = JSON.parse(localStorage.getItem('specialization1'));

            existingSpecializations = existingSpecializations || [];

            localStorage?.setItem('specialization1', JSON.stringify([...existingSpecializations, newSpecialization]));
            setSpecializationName("");
        }
    }


    function handleDeleteSpecialization(index) {
        setErrors('')
        if (index !== -1) {
            const updatedSpecialization = [...info.specialization
                .filter(item => item.name.trim() !== '')];

            updatedSpecialization.splice(index, 1);

            setInfo(prevInfo => ({
                ...prevInfo,
                specialization: updatedSpecialization
            }));

            localStorage.setItem('specialization1', JSON.stringify(updatedSpecialization));
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (handleValidation()) {
            postApi()
        }

    };

    async function getStaffApi() {
        setLoading(true);
        let result = await Services.getService(`admin/trainer-details?id=${id}`);
        if (result.code === DEVELOPMENT_CONFIG.statusCode) {
            let specializationArray = Array.isArray(result?.body?.specialization)
                ? result?.body?.specialization.map(name => ({ name }))
                : result?.body?.specialization?.split(',')?.map(name => ({ name }));

            setInfo({
                ...result.body,
                specialization: specializationArray,
                shift: result.body.shift_hours,
                password: result.body.password
            });
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    async function getApi() {
        const company_id = localStorage.getItem("company_id")
        let result = await Services.getService(`admin/role-branch-list?id=${company_id}`);
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setRole(result?.body?.roleData)
            setBranch(result?.body?.branch)
        }
        else {
        }
    }

    useEffect(() => {
        getStaffApi()
        getApi()
    }, [])

    return (
        <div>
            <Helmet>
                <title>{TITLES.ADD_STAFF}</title>
            </Helmet>
            {loading && <ReactLoader />}
            <section className="add-customer common-bg form-role_id">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="add-customer-head mb-2 mb-lg-5">
                                <h2 className="add-customer-text fw-600">Edit Staff</h2>
                            </div>
                        </div>
                        <div className="col-12">
                            <form>
                                <div className="row">
                                    <div className="col-12 col-lg-2">
                                        <div className=" position-relative">
                                            <div className={`profile-pic ${info.profile_pic !== "" ? 'profile-pic-change' : ''}`} style={info.profile_pic === "" ? { border: "1px solid grey" } : {}}>
                                                <div className={info.profile_pic === "" ? "profile-upper-pic" : ""}>
                                                    <img src={info.profile_pic ? info.profile_pic : USER_PROFILE} className={`img-fluid  ${info.profile_pic !== "" ? 'img-preview ' : ""}`} id="blah" />

                                                </div>
                                            </div>
                                            <input type="file" name="imageUrl" className="input-profile" id="image" accept=".jpg, .jpeg, .png" onChange={fileChange1} />
                                            {errors.imageUrl && <span className="err_msg">{errors.imageUrl}</span>}
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-10">
                                        <div className="row">
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">name</label>
                                                    <input type="text" name='name' value={info.name} onChange={handleChange} className="form-control common-input" placeholder="Enter Name" />
                                                    {errors.name && <span className="err_msg">{errors.name}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Mobile No</label>
                                                    <input type="text" name='mobile_number' value={info.mobile_number?.slice(0, 10)} onChange={handleChange} className="form-control common-input" placeholder="Enter Mobile No" />
                                                    {errors.mobile_number && <span className="err_msg">{errors.mobile_number}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Whatsapp  No</label>
                                                    <input type="text" name='whatsapp_no' value={info.whatsapp_no?.slice(0, 10)} onChange={handleChange} className="form-control common-input" placeholder="Enter Whatsapp  No" />
                                                    {errors.whatsapp_no && <span className="err_msg">{errors.whatsapp_no}</span>}

                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Emergency No</label>
                                                    <input type="text" name='emergency_no' value={info.emergency_no?.slice(0, 10)} onChange={handleChange} className="form-control common-input" placeholder="Enter ALternate No" />
                                                    {errors.emergency_no && <span className="err_msg">{errors.emergency_no}</span>}

                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Experience</label>
                                                    <input type="text" name='experience' value={info.experience} onChange={handleChange} className="form-control common-input" placeholder="Enter Experience" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Email</label>
                                                    <input type="text" name='email' value={info.email} onChange={handleChange} className="form-control common-input" placeholder="Enter Email" />
                                                    {errors.email && <span className="err_msg">{errors.email}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Role</label>
                                                    <select type="text" name='role_id' value={info.role_id} onChange={handleChange} className="form-select common-input" aria-label="Enter Role">

                                                        {
                                                            role?.map((item) => {
                                                                return (
                                                                    <option key={item.id} value={item.id}>{item?.name}</option>

                                                                )
                                                            })
                                                        }

                                                    </select>
                                                    {errors.role_id && <span className="err_msg">{errors.role_id}</span>}
                                                </div>
                                            </div>
                                            {/* {
                                                info?.role_id == 1 &&
                                                <> <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                    <div className="mb-3">
                                                        <label className="form-label">Password</label>
                                                        <input type="text" name='password' value={info.password} onChange={handleChange} className="form-control common-input" placeholder="Enter Pswword" />
                                                        {errors.password && <span className="err_msg">{errors.password}</span>}
                                                    </div>
                                                </div>
                                                </>
                                            } */}
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Shift time (in hours)</label>
                                                    <select name='shift' value={info.shift} onChange={handleChange} disabled={info.role == 1} className="form-select common-input" aria-label="Default select example">
                                                        <option value="" >Select</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                    </select>
                                                    {errors.shift && <span className="err_msg">{errors.shift}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Branch</label>
                                                    {
                                                         localStorage.getItem("role_id") != 1
                                                         ? <select type="text" name='branch_id' value={info.branch_id} onChange={handleChange} className="form-select common-input" aria-label="Enter Role">
                                                         {
                                                             branch_id?.map((item) => {
                                                                 return (
                                                                     <option key={item.id} value={item.id}>{item?.address_line_2},{item.city}</option>
 
                                                                 )
                                                             })
                                                         }
                                                     </select>:
                                                      <select type="text" name='branch_id' value={info.branch_id} onChange={handleChange} disabled className="form-select common-input" aria-label="Enter Role">
                                                      {
                                                          branch_id?.filter(item => item.id == localStorage.getItem("branch_id")).map((item) => {
                                                              return (
                                                                  <option key={item.id} value={item.id}>{item?.address_line_2},{item.city}</option>

                                                              )
                                                          })
                                                      }
                                                  </select>
                                                    }
                                                   
                                                    {errors.branch_id && <span className="err_msg">{errors.branch_id}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Gender</label>
                                                    <select name='gender' value={info.gender} onChange={handleChange} className="form-select common-input" aria-label="Default select example">
                                                        <option value={info.gender}>{info.gender == 1 ? "Male" : info.gender == 2 ? "Female" : info.gender == 3 ? "Others" : "Select"}</option>
                                                        {info.gender === "2" ? null : <option value="2">Female</option>}
                                                        {info.gender === "1" ? null : <option value="1">Male</option>}
                                                        {info.gender === "3" ? null : <option value="3">Others</option>}
                                                    </select>
                                                    {errors.gender && <span className="err_msg">{errors.gender}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Blood group</label>

                                                    <select name='blood_group' value={info.blood_group} onChange={handleChange} className="form-select common-input" aria-label="Blood group select">
                                                        <option value="">Select</option>
                                                        <option value="A+">A+</option>
                                                        <option value="A-">A-</option>
                                                        <option value="B+">B+</option>
                                                        <option value="B-">B-</option>
                                                        <option value="AB+">AB+</option>
                                                        <option value="AB-">AB-</option>
                                                        <option value="O+">O+</option>
                                                        <option value="O-">O-</option>
                                                    </select>

                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Address line 1</label>
                                                    <input type="text" name='address_1' value={info.address_1} onChange={handleChange} className="form-control common-input" placeholder="Enter Address" />
                                                    {errors.address_1 && <span className="err_msg">{errors.address_1}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">Address line 2(optional)</label>
                                                    <input type="text" name='address_2' value={info.address_2} onChange={handleChange} className="form-control common-input" placeholder="Enter Address" />

                                                </div>
                                            </div>

                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">state</label>
                                                    <input type="text" name='state' value={info.state} onChange={handleChange} className="form-control common-input" placeholder="Enter State" />
                                                    {errors.state && <span className="err_msg">{errors.state}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">city</label>
                                                    <input type="text" name='city' value={info.city} onChange={handleChange} className="form-control common-input" placeholder="Enter City" />
                                                    {errors.city && <span className="err_msg">{errors.city}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-6 col-lg-6 col-xl-4">
                                                <div className="mb-3">
                                                    <label className="form-label">zip code</label>
                                                    <input type="number" name='pincode' value={info.pincode?.slice(0, 6)} onChange={handleChange} className="form-control common-input" placeholder="Enter Zip code" />
                                                    {errors.pincode && <span className="err_msg">{errors.pincode}</span>}
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="mb-3">
                                                    <label className="form-label">About </label>
                                                    <textarea name="about" className="form-control common-input remark-textarea" rows="5" onChange={handleChange1}
                                                        placeholder="Enter" value={info?.about}></textarea>
                                                    {errors.about && <span className="err_msg">{errors.about}</span>}
                                                </div>

                                            </div>
                                            <div className="col-12">
                                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                    <label className="form-label">Specializations </label>
                                                    {/* <button className="add-btn btn" onClick={handleDeleteOpen}>+ ADD</button> */}
                                                </div>
                                                <div>
                                                    <div className='d-flex gap-2 align-items-strectch mb-3'>
                                                        <div className='col-lg-3 col-lg-3 col-sm-3 col-9'>
                                                            <input type="text" name="specialization1_name" onChange={handleChange2}
                                                                value={specialization1_name} className='form-control common-input' placeholder='Enter' />
                                                        </div>
                                                        <button type="button" className="add-btn btn add-field  next-btn" onClick={specializationApi}>ADD</button>
                                                    </div>

                                                </div>
                                                {
                                                    info?.specialization && info.specialization.length > 0 && (
                                                        <div className="d-flex align-items-center flex-wrap my-2 gap-2">
                                                            {info.specialization
                                                                .filter(item => item.name.trim() !== '')
                                                                .map((item, index) => (
                                                                    <div key={item?.id}>
                                                                        <span className="specializations-span d-flex align-items-center gap-3" style={{ cursor: 'pointer' }}>
                                                                            {item.name} <img onClick={() => handleDeleteSpecialization(index)} src={CROSSED} alt="" />
                                                                        </span>
                                                                    </div>
                                                                ))}

                                                        </div>
                                                    )
                                                }
                                                <>
                                                    {errors.specialization && <span className="err_msg">{errors.specialization}</span>}</>
                                            </div>
                                        </div>
                                        <div className="col-12 text-end">
                                            <button type="submit" className=" next-btn" onClick={handleSubmit}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </div>
    )
}

export default EditStaff;